import React from "react";
import reqImg from "../../assets/images/homepage/request.png";
import reqImg2 from "../../assets/images/homepage/request2.png";
import { Col, Row } from "reactstrap";

const RequestDemo = props => {
  return (
    <div className="position-relative mb-5">
      <Row className="p-0 m-0 request-demo d-xl-none">
        <div className="col-12 col-md-6 col-xl-8">
          <img src={reqImg} alt="request" className="img-fluid" />
        </div>
        <div className="col-12 col-md-6 col-xl-6 px-0 card">
          <div className="card-body p-xl-0 m-xl-0">
            <div className="card-heading d-flex justify-content-center">
              <h4 className="underline">Request For Demo</h4>
            </div>
            <p className="m-0">
              Leverage our cutting-edge technology and data analytics to
              increase recovery rates. Embrace innovation with <b>Recoveryfy</b>
              .
            </p>
          </div>
          <div className="mt-1">
            <div className="btn" onClick={props.modal}>
              Request for Demo
            </div>
          </div>
        </div>
      </Row>
      <Row className="p-0 m-0 request-demo d-none d-xl-flex">
        <Col className="px-0">
          <img src={reqImg2} alt="request" className="img-fluid" />
        </Col>
        <div className="col-12 col-md-6 col-xl-6 px-0 card">
          <div className="card-body p-xl-0 m-xl-0">
            <div className="card-heading d-flex justify-content-center">
              <h4 className="underline">Request For Demo</h4>
            </div>
            <p className="m-0">
              Leverage our cutting-edge technology and data analytics to
              increase recovery rates. Embrace innovation with <b>Recoveryfy</b>
              .
            </p>
          </div>
          <div className="">
            <div className="btn" onClick={props.modal}>
              Request for Demo
            </div>
          </div>
        </div>
      </Row>
    </div>
  );
};

export default RequestDemo;
