import { takeEvery, put, call } from "redux-saga/effects";

// Login Redux States
import { FORGET_PASSWORD } from "./actionTypes";
import { userForgetPasswordSuccess, userForgetPasswordError } from "./actions";

//Include Both Helper File with needed methods
import { getFirebaseBackend } from "../../../helpers/firebase_helper";
import {
  postForgetPwd,
  postJwtForgetPwd,
} from "../../../helpers/backend_helper";

//If user is send successfully send mail link then dispatch redux action's are directly from here.
function* forgetUser({ payload: { user, history } }) {
  try {
    const response = yield call(postForgetPwd, { email: user.email });

    if (response) {
      yield put(userForgetPasswordSuccess(response.msg));
    } else {
      yield put(userForgetPasswordError("Somethings went wrong."));
    }
  } catch (error) {
    // console.log(error.response.data.msg);
    yield put(userForgetPasswordError(error.response.data.msg));
  }
}

function* forgetPasswordSaga() {
  yield takeEvery(FORGET_PASSWORD, forgetUser);
}

export default forgetPasswordSaga;
