import React, { useEffect, useState } from "react";
import MetaTags from "react-meta-tags";
import { Container, Row, Col, Card, CardBody, CardTitle } from "reactstrap";

//import action
import { getChartsData, getSummaryAction } from "../../store/actions";

// Pages Components
import WelcomeComp from "./WelcomeComp";
import MonthlyEarning from "./MonthlyEarning";

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";

//i18n
import { toast } from "react-toastify";
import Loader from "components/Common/Loader";
import DataGraph from "./DataGraph";

const Dashboard = props => {
  const [record, setRecord] = useState({});

  useEffect(async () => {
    try {
      props.setLoading(true);
      const response = await getSummaryAction();
      if (response.status === 1) {
        props.setLoading(false);
        let obj = response?.data || {};
        setRecord({ ...obj, liveProgress: parseInt(obj.liveProgress) });
      } else {
        toast.error(response.msg);
        props.setLoading(false);
      }
    } catch (error) {
      props.setLoading(false);
      let err = error?.response?.data?.msg || "Something went wrong";
      toast.error(err);
    }
  }, []);

  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>Dashboard | Recoveryfy</title>
        </MetaTags>
        <Container fluid>
          {/* Render Breadcrumb */}
          {/* <Breadcrumbs
            title={"Dashboards"}
            breadcrumbItem={"Dashboard"}
            path={"/panel/dashboard"}
          /> */}
          <Row>
            <Col xl="4">
              <WelcomeComp />
              <MonthlyEarning data={record} />
            </Col>
            <Col xl="8">
              <Row>
                {/* totalLive : 1 totalPending : 32 totalHold : 0 totalClose : */}
                <Col md="3">
                  <Card className="mini-stats-wid">
                    <CardBody>
                      <div className="d-flex">
                        <div className="flex-grow-1">
                          <p className="text-muted fw-medium">
                            Total
                            <br /> Live
                          </p>
                          <h4 className="mb-0">{record.totalLive}</h4>
                        </div>
                        <div className="mini-stat-icon avatar-sm rounded-circle bg-primary align-self-center">
                          <span className="avatar-title">
                            <span className="material-icons-outlined md-24">
                              stream
                            </span>
                          </span>
                        </div>
                      </div>
                    </CardBody>
                  </Card>
                </Col>
                <Col md="3">
                  <Card className="mini-stats-wid">
                    <CardBody>
                      <div className="d-flex">
                        <div className="flex-grow-1">
                          <p className="text-muted fw-medium">
                            Total
                            <br /> Pending
                          </p>
                          <h4 className="mb-0">{record.totalPending}</h4>
                        </div>
                        <div className="mini-stat-icon avatar-sm rounded-circle bg-primary align-self-center">
                          <span className="avatar-title">
                            <span className="material-icons-outlined md-24">
                              hourglass_empty
                            </span>
                          </span>
                        </div>
                      </div>
                    </CardBody>
                  </Card>
                </Col>
                <Col md="3">
                  <Card className="mini-stats-wid">
                    <CardBody>
                      <div className="d-flex">
                        <div className="flex-grow-1">
                          <p className="text-muted fw-medium">
                            Total
                            <br /> Hold
                          </p>
                          <h4 className="mb-0">{record.totalHold}</h4>
                        </div>
                        <div className="mini-stat-icon avatar-sm rounded-circle bg-primary align-self-center">
                          <span className="avatar-title">
                            <span className="material-icons-outlined md-24">
                              pause_circle_filled
                            </span>
                          </span>
                        </div>
                      </div>
                    </CardBody>
                  </Card>
                </Col>
                <Col md="3">
                  <Card className="mini-stats-wid">
                    <CardBody>
                      <div className="d-flex">
                        <div className="flex-grow-1">
                          <p className="text-muted fw-medium">
                            Total
                            <br /> Close
                          </p>
                          <h4 className="mb-0">{record.totalClose}</h4>
                        </div>
                        <div className="mini-stat-icon avatar-sm rounded-circle bg-primary align-self-center">
                          <span className="avatar-title">
                            <span className="material-icons-outlined md-24">
                              block
                            </span>
                          </span>
                        </div>
                      </div>
                    </CardBody>
                  </Card>
                </Col>
                {/* <Col md="4">
                  <Card className="mini-stats-wid">
                    <CardBody>
                      <div className="d-flex">
                        <div className="flex-grow-1">
                          <p className="text-muted fw-medium">Total Record</p>
                          <h4 className="mb-0">
                            {record.totalClose +
                              record.totalPending +
                              record.totalLive +
                              record.totalHold}
                          </h4>
                        </div>
                        <div className="mini-stat-icon avatar-sm rounded-circle bg-primary align-self-center">
                          <span className="avatar-title">
                            <span className="material-icons-outlined md-24">
                              summarize
                            </span>
                          </span>
                        </div>
                      </div>
                    </CardBody>
                  </Card>
                </Col> */}
              </Row>

              <Card>
                <CardBody>
                  <div className="d-sm-flex flex-wrap">
                    <CardTitle className="card-title mb-4 h4">
                      Monthly Record
                    </CardTitle>
                  </div>
                  <div className="clearfix" />
                  <DataGraph data={record.last6MonthRecord} />
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default Loader(Dashboard);
