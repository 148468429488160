import React from "react";
import logo from "../../assets/images/homepage/logo1.png";
import playStore from "../../assets/images/homepage/google-play.png";
import appleStore from "../../assets/images/homepage/apple-logo.png";
import { Container } from "reactstrap";
import { scrollToSection } from "helpers/common_helper";

const Footer = () => {
  return (
    <Container fluid className="px-xl-5 footer-container">
      <div className="row mb-5">
        <div className="col-12 input-heading mb-4">
          <p>Stay informed and up-to-date by subscribing to our newsletter.</p>
        </div>
        <div className="col-12 col-lg-9 d-flex align-items-center gap-4">
          <div className="input-group">
            <input
              type="email"
              className="form-control"
              placeholder="Enter Your Email"
              aria-label="Recipient's username"
              aria-describedby="QueryEmail"
            />
            <div className="input-group-append btn">Subscribe</div>
          </div>
        </div>
        <div className="col-12 col-lg-3 mt-4 mt-lg-0 d-flex align-items-center justify-content-end social-media">
          <i className="fab fa-facebook-f"></i>
          <i className="fab fa-twitter"></i>
          <i className="fab fa-linkedin-in"></i>
          <i className="fab fa-instagram"></i>
        </div>
      </div>
      <hr />
      <div className="row my-2">
        <div className="col-12 col-md-4 col-lg-4 my-2">
          <img
            src={"https://recovery.newitzone.com/api/public/profile/logo.png"}
            alt="logo"
            className="brand-logo"
          />
          <div className="summary">
            Trusted for Repossession Services, Agent Tracking, Data Analysis,
            Optimized Strategies and Data Security.
          </div>
          <div className="address">
            <i className="fas fa-map-marker-alt me-2"></i>
            <span>Lado Sarai, Mehrauli, New Delhi -110030</span>
          </div>
          <div className="mail">
            <i className="far fa-envelope me-2"></i>
            <a href="mailto:contact@adciasis.com">
              <span className="email">contact@adciasis.com</span>
            </a>
          </div>
        </div>
        <div className="col-12 col-md-4 col-lg-4 my-2 link-list">
          <div className="d-flex align-items-md-center flex-column g-22">
            <h6>Company</h6>
            <ul className="p-0">
              <li onClick={e => scrollToSection(e, "home")}>Home</li>
              <li onClick={e => scrollToSection(e, "aboutus")}>About Us</li>
              <li onClick={e => scrollToSection(e, "testimonial")}>
                Testmonials
              </li>
              <li onClick={e => scrollToSection(e, "features")}>Features</li>
              <li onClick={e => scrollToSection(e, "contact")}>Contact Us</li>
            </ul>
          </div>
        </div>
        <div className="col-12 col-md-4 col-lg-4 my-2 d-flex align-items-lg-end flex-column">
          <div className="app">
            <h6 className="mb-3">Download App</h6>
            <div className="play-store d-flex align-items-center">
              <div className="logo">
                <img src={playStore} alt="google play store logo" />
              </div>
              <div className="">
                <div className="h5 mb-0 text-white">Available on the </div>
                <div className="h4 text-white">Google Play </div>
              </div>
            </div>
            <div className="apple-store d-flex align-items-center">
              <div className="logo">
                {" "}
                <img src={appleStore} alt="apple store logo" />
              </div>
              <div className="">
                <div className="h5 mb-0 text-white">Available on the </div>
                <div className="h4 text-white">App Store </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <hr />

      <div className="row">
        <div className="col-12 mt-3 mb-4">
          <span className="copyright d-flex align-items-center justify-content-center">
            © Copyright Recoveryfy. All Rights Reserved
          </span>
        </div>
      </div>
    </Container>
  );
};

export default Footer;
