import React from "react";
import { Container } from "reactstrap";
import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore, { Autoplay, Pagination } from "swiper";
import "swiper/swiper-bundle.min.css";
import img1 from "../../assets/images/homepage/Testimonial1.png";
import vector from "../../assets/images/homepage/Vector.png";

SwiperCore.use([Pagination, Autoplay]); // Initialize Swiper modules

const TestimonialCarousel = () => {
  return (
    <Container id="testimonial" fluid className="mb-5 testimonial">
      <div className="text-center">
        <h3 className="my-2 underline pb-2">Testimonials</h3>
      </div>
      <Swiper
        slidesPerView={1}
        spaceBetween={20}
        centeredSlides={true}
        pagination={{
          clickable: true,
        }}
        loop={true}
        autoplay={{
          delay: 2500,
          disableOnInteraction: false,
        }}
        breakpoints={{
          768: {
            slidesPerView: 2,
            spaceBetween: 10,
          },
          1200: {
            slidesPerView: 3,
            spaceBetween: 10,
          },
          1400: {
            slidesPerView: 3,
            spaceBetween: 25,
          },
        }}
        className="slider"
      >
        <SwiperSlide>
          <div className="card">
            <div className="card-body">
              <img src={vector} alt="vector" className="vector" />
              <p>
                {`Recoveryfy's`} platform has transformed our debt recovery
                operations. The advanced features, such as real-time dashboards
                and agent tracking, have significantly improved our efficiency
                and recovery rates. We highly appreciate their innovative
                solution.
              </p>
              <hr className="mt-5" />
            </div>
            <div className="card-footer bg-transparent pb-5">
              <div className="d-flex align-items-center gap-2">
                <img src={img1} alt="testimage" />
                <div className="ms-2">
                  <h6>PQR Financial Solutions</h6>
                  <p>Agent</p>
                </div>
              </div>
            </div>
          </div>
        </SwiperSlide>
        <SwiperSlide>
          <div className="card">
            <div className="card-body">
              <img src={vector} alt="vector" className="vector" />
              <p>
                Recoveryfy has been a game-changer for our agency. The platform
                provides a common ground for banks, NBFCs, and repossession
                agencies, streamlining collaboration and communication. It has
                truly revolutionized the way we recover debts.
              </p>
              <hr className="mt-5" />
            </div>
            <div className="card-footer bg-transparent pb-5">
              <div className="d-flex align-items-center gap-2">
                <img src={img1} alt="testimage" />
                <div className="ms-2">
                  <h6>ABC Recovery Agency </h6>
                  <p>Agent</p>
                </div>
              </div>
            </div>
          </div>
        </SwiperSlide>
        <SwiperSlide>
          <div className="card">
            <div className="card-body">
              <img src={vector} alt="vector" className="vector" />
              <p>
                {`Recoveryfy's`} comprehensive accounting services have been a
                game-changer for us. Their attention to detail and meticulous
                approach have ensured transparency and accuracy in our financial
                records. We are grateful for their exceptional support.
              </p>
              <hr className="mt-5" />
            </div>
            <div className="card-footer bg-transparent pb-5">
              <div className="d-flex align-items-center gap-2">
                <img src={img1} alt="testimage" />
                <div className="ms-2">
                  <h6>UVW Financial Institution</h6>
                  <p>Agent</p>
                </div>
              </div>
            </div>
          </div>
        </SwiperSlide>
        <SwiperSlide>
          <div className="card">
            <div className="card-body">
              <img src={vector} alt="vector" className="vector" />
              <p>
                Recoveryfy is our go-to solution for debt recovery. Their
                {`platform's`} tracking agent activity feature has provided us
                with real-time updates on agent performance, allowing us to
                optimize our strategies. They are a reliable and trusted
                partner.
              </p>
              <hr className="mt-5" />
            </div>
            <div className="card-footer bg-transparent pb-5">
              <div className="d-flex align-items-center gap-2">
                <img src={img1} alt="testimage" />
                <div className="ms-2">
                  <h6>EFG Collections Agency</h6>
                  <p>Agent</p>
                </div>
              </div>
            </div>
          </div>
        </SwiperSlide>
        <SwiperSlide>
          <div className="card">
            <div className="card-body">
              <img src={vector} alt="vector" className="vector" />
              <p>
                Recoveryfy is unmatched in professionalism, expertise, and
                commitment to client satisfaction. Their comprehensive solution
                has exceeded our expectations, and we highly recommend their
                services to anyone in need of efficient debt recovery.
              </p>
              <hr className="mt-5" />
            </div>
            <div className="card-footer bg-transparent pb-5">
              <div className="d-flex align-items-center gap-2">
                <img src={img1} alt="testimage" />
                <div className="ms-2">
                  <h6>HIJ Credit Services</h6>
                  <p>Agent</p>
                </div>
              </div>
            </div>
          </div>
        </SwiperSlide>
      </Swiper>
    </Container>
  );
};

export default TestimonialCarousel;
