import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

import { logoutAction, logoutUser } from "../../store/actions";

function Logout({ history, logoutUser }) {
  useEffect(async () => {
    await logoutAction();
    logoutUser(history);
  }, [history, logoutUser]);

  return null; // Since this component doesn't render anything, return null or a placeholder
}

Logout.propTypes = {
  history: PropTypes.any,
  logoutUser: PropTypes.func,
};

export default withRouter(connect(null, { logoutUser })(Logout));
