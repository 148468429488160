import React, { useEffect, useState } from "react";

import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Container,
  Row,
  Col,
  Alert,
  Label,
} from "reactstrap";
import CustomCloseButton from "./CustomCloseButton";
import PropTypes from "prop-types";
// Redux
import { connect, useSelector } from "react-redux";
import { Link, withRouter } from "react-router-dom";

import { Formik, Field, Form, ErrorMessage } from "formik";
import * as Yup from "yup";

import { toast } from "react-toastify";

// action
import {
  userForgetPassword,
  userForgetPasswordSuccess,
  userForgetPasswordError,
  forgetPwd,
} from "../../store/actions";
import { RotatingLines } from "react-loader-spinner";

function ForgetModal(props) {
  const logo =
    "https://recovery.newitzone.com/api/public/assets/images/Logo2.png";
  const [modal, setModal] = useState(false);
  const [loading, setLoading] = useState(false);

  const toggle = () => {
    setModal(!modal);
    props.isToggle();
  };

  useEffect(() => {
    setModal(props.isOpen);
  }, [props.isOpen]);
  useEffect(() => {
    setLoading(false);
  }, [props.forgetError, props.forgetSuccessMsg]);

  return (
    <div>
      <Modal
        isOpen={modal}
        toggle={toggle}
        centered
        backdrop={false}
        className="homepage-modal"
        size="custom"
      >
        <ModalHeader
          toggle={toggle}
          close={<CustomCloseButton onClick={toggle} />}
          className="header"
        >
          <img src={logo} alt="logo" className="brand-logo" />
        </ModalHeader>
        <ModalBody className="pb-5">
          <Container className="px-3">
            <Row className="justify-content-center">
              <Col>
                <Row>
                  <Col>
                    <div className="text-primary py-4">
                      <div className="h2">
                        {`Can't`} Remember Your Password? No Problem!
                      </div>
                    </div>
                  </Col>
                </Row>
                <div className="p-2">
                  {props.forgetError && props.forgetError ? ( // Extract the error message from the object
                    <Alert color="danger" style={{ marginTop: "13px" }}>
                      {props.forgetError}
                    </Alert>
                  ) : null}
                  {props.forgetSuccessMsg ? (
                    <Alert color="success" style={{ marginTop: "13px" }}>
                      {props.forgetSuccessMsg}
                    </Alert>
                  ) : null}

                  <Formik
                    enableReinitialize={true}
                    initialValues={{
                      email: "",
                    }}
                    validationSchema={Yup.object().shape({
                      email: Yup.string()
                        .email("Please provide a valid email address.")
                        .required("Please enter your email address."),
                    })}
                    onSubmit={(values, action) => {
                      setLoading(true);
                      props.userForgetPassword(values, props.history);
                      action.resetForm();
                    }}
                  >
                    {({ errors, status, touched }) => (
                      <Form className="form-horizontal">
                        <div className="mb-3">
                          <Label for="email" className="form-label">
                            Enter your email below and {`we'll`} send you a link
                            to create a new password.
                          </Label>
                          <Field
                            name="email"
                            type="text"
                            style={{
                              backgroundImage: "none",
                            }}
                            placeholder="Email"
                            className={
                              "form-control" +
                              (errors.email && touched.email
                                ? " is-invalid"
                                : "")
                            }
                          />
                          <ErrorMessage
                            name="email"
                            component="div"
                            className="invalid-feedback"
                          />
                        </div>
                        <div className="row button-container">
                          <div className="col-12 col-md-6 mt-2">
                            <button
                              className={`btn btn-block btn-danger ${
                                loading ? "disabled" : ""
                              }`}
                              type="submit"
                            >
                              {loading ? (
                                <RotatingLines
                                  strokeColor="white"
                                  strokeWidth="5"
                                  animationDuration="0.75"
                                  width="35"
                                  visible={true}
                                />
                              ) : (
                                "Submit"
                              )}
                            </button>
                          </div>
                          <div
                            className="col-12 col-md-6 mt-2"
                            onClick={toggle}
                          >
                            <button className="btn btn-block btn-outline-danger">
                              Cancel
                            </button>
                          </div>
                        </div>
                      </Form>
                    )}
                  </Formik>
                </div>
              </Col>
            </Row>
          </Container>
        </ModalBody>
      </Modal>
    </div>
  );
}

ForgetModal.propTypes = {
  forgetError: PropTypes.string, // Update the prop type to string
  forgetSuccessMsg: PropTypes.object,
  history: PropTypes.object,
  userForgetPassword: PropTypes.func, // Update the prop type to func
};

const mapStateToProps = state => {
  const { forgetError, forgetSuccessMsg } = state.ForgetPassword;
  return { forgetError, forgetSuccessMsg };
};

export default withRouter(
  connect(mapStateToProps, { userForgetPassword })(ForgetModal)
);
