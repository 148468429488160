import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Dropdown, DropdownToggle, DropdownMenu, Row, Col } from "reactstrap";
import SimpleBar from "simplebar-react";

//Import images
import avatar3 from "../../../assets/images/users/avatar-3.jpg";
import avatar4 from "../../../assets/images/users/avatar-4.jpg";

//i18n
import { withTranslation } from "react-i18next";
import { requestPermission, onMessageListener } from "../../Common/firebase";
import {
  notificationDetailsAction,
  notificationReadAction,
} from "store/actions";
const NotificationDropdown = ({ t }) => {
  const [menu, setMenu] = useState(false);

  const toggle = () => {
    setMenu(prevMenu => !prevMenu);
  };

  const [notification, setNotification] = useState([]);
  const [unreadCount, setUnreadCount] = useState(0);
  const [notificationList, setNotificationList] = useState([]);

  const getNotificationDetails = async () => {
    const response = await notificationDetailsAction();
    if (response.status == 1) {
      const count = response.data.filter(item => item.isRead === 0).length;
      setNotificationList(response.data);
      setNotification(response.data.slice(0, 20));
      setUnreadCount(count);
    } else {
      console.log(response);
    }
  };

  useEffect(async () => {
    try {
      await getNotificationDetails();
    } catch (error) {
      console.log(error);
    }
  }, []);

  const handleReadNotification = async id => {
    try {
      const response = await notificationReadAction({ ids: [id] });
      if (response.status == 1) {
        await getNotificationDetails();
      }
    } catch (error) {
      console.log(error);
    }
  };

  const loadNotification = () => {
    let addLength = notification.length + 20;
    if (addLength > notificationList.length) {
      addLength = notificationList.length;
    }
    setNotification(notificationList.slice(0, addLength));
  };

  return (
    <React.Fragment>
      <Dropdown
        isOpen={menu}
        toggle={toggle}
        className="dropdown d-inline-block"
        tag="li"
      >
        <DropdownToggle
          className="btn header-item noti-icon"
          tag="button"
          id="page-header-notifications-dropdown"
        >
          <i className="bx bx-bell bx-tada" />
          <span className="badge bg-danger rounded-pill">
            {unreadCount > 0 && unreadCount}
          </span>
        </DropdownToggle>

        <DropdownMenu className="dropdown-menu dropdown-menu-lg dropdown-menu-end p-0">
          <div className="p-3">
            <Row className="align-items-center">
              <Col>
                <h5 className="m-0">{t("Notifications")} </h5>
              </Col>
            </Row>
          </div>
          {/* new Date(data).toLocaleString("en-GB") */}
          <SimpleBar style={{ height: "230px" }}>
            {notification.map((item, index) => {
              const dataTime = new Date(item.createdAt).toLocaleString("en-GB");
              return (
                <div
                  key={item.id}
                  className={`text-reset notification-item ${
                    item.isRead == 0 ? "unread" : ""
                  }`}
                  onClick={() => {
                    item.isRead == 0 ? handleReadNotification(item.id) : "";
                  }}
                >
                  <div className="d-flex justify-content-between align-items-center ">
                    <div>
                      <h6 className="mt-0 mb-1">{t(item.sortMessage)}</h6>
                      <div className="font-size-12 text-muted">
                        <p className="mb-1">{t(item.longMessage)}</p>
                        <p className="mb-0">
                          <i className="mdi mdi-clock-outline" /> {dataTime}
                        </p>
                      </div>
                    </div>
                    <div>
                      <div className="badge-dot"></div>
                    </div>
                  </div>
                </div>
              );
            })}
          </SimpleBar>
          <div
            className={`p-2 border-top d-grid ${
              notificationList.length === notification.length ? "d-none" : ""
            }`}
          >
            <div
              className="btn btn-sm btn-link font-size-14 text-center"
              onClick={loadNotification}
            >
              <i className="mdi mdi-arrow-right-circle me-1"></i>{" "}
              <span key="t-view-more">{t("View More..")}</span>
            </div>
          </div>
        </DropdownMenu>
      </Dropdown>
    </React.Fragment>
  );
};

NotificationDropdown.propTypes = {
  t: PropTypes.any,
};

export default withTranslation()(NotificationDropdown);
