import React from "react";
import ReactApexChart from "react-apexcharts";
import "./dashboard.scss";

const ApexRadial = props => {
  const options = {
    chart: {
      height: 60,
      type: "radialBar",
      offsetY: -30,
    },
    plotOptions: {
      radialBar: {
        startAngle: -135,
        endAngle: 135,
        dataLabels: {
          name: {
            fontSize: "13px",
            color: undefined,
            offsetY: 55,
          },
          value: {
            offsetY: 22,
            fontSize: "16px",
            color: undefined,
            formatter: function (e) {
              return e;
            },
          },
        },
      },
    },
    colors: ["#556ee6"],
    fill: {
      type: "gradient",
      gradient: {
        shade: "dark",
        shadeIntensity: 0.15,
        inverseColors: false,
        opacityFrom: 1,
        opacityTo: 1,
        stops: [0, 50, 65, 91],
      },
    },
    stroke: {
      dashArray: 4,
    },
    labels: ["Closed"],
  };

  const series = [props.value || 0];

  return (
    <React.Fragment>
      <ReactApexChart
        options={options}
        series={series}
        type="radialBar"
        height={150}
        className="apex-charts"
      />
    </React.Fragment>
  );
};

export default ApexRadial;
