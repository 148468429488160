import React from "react";
import { Redirect } from "react-router-dom";

// Dashboard
import Dashboard from "../pages/Dashboard/index";
// Authentication related pages
import Login from "../pages/Authentication/Login";
import Logout from "../pages/Authentication/Logout";

//Tables
import BasicTables from "../pages/Tables/BasicTables";
import DatatableTables from "../pages/Tables/DatatableTables";
import ResponsiveTables from "../pages/Tables/ResponsiveTables";
import EditableTables from "../pages/Tables/EditableTables";
import DragDropTables from "../pages/Tables/DragDropTables";

// Forms
import FormElements from "../pages/Forms/FormElements/index";
import FormLayouts from "../pages/Forms/FormLayouts";
import FormAdvanced from "../pages/Forms/FormAdvanced";
import FormEditors from "../pages/Forms/FormEditors";
import FormValidations from "../pages/Forms/FormValidations";
import FormMask from "../pages/Forms/FormMask";
import FormRepeater from "../pages/Forms/FormRepeater";
import FormUpload from "../pages/Forms/FormUpload";
import FormWizard from "../pages/Forms/FormWizard";
import FormXeditable from "../pages/Forms/FormXeditable";
import DualListbox from "../pages/Forms/DualListbox";

//Ui
import UiAlert from "../pages/Ui/UiAlert";
import UiButtons from "../pages/Ui/UiButtons";
import UiCards from "../pages/Ui/UiCards";
import UiCarousel from "../pages/Ui/UiCarousel";
import UiColors from "../pages/Ui/UiColors";
import UiDropdown from "../pages/Ui/UiDropdown";
import UiGeneral from "../pages/Ui/UiGeneral";
import UiGrid from "../pages/Ui/UiGrid";
import UiImages from "../pages/Ui/UiImages";
import UiLightbox from "../pages/Ui/UiLightbox";
import UiModal from "../pages/Ui/UiModal";
import UiProgressbar from "../pages/Ui/UiProgressbar";
import UiSweetAlert from "../pages/Ui/UiSweetAlert";
import UiTabsAccordions from "../pages/Ui/UiTabsAccordions";
import UiTypography from "../pages/Ui/UiTypography";
import UiVideo from "../pages/Ui/UiVideo";
import UiSessionTimeout from "../pages/Ui/UiSessionTimeout";
import UiRating from "../pages/Ui/UiRating";
import UiRangeSlider from "../pages/Ui/UiRangeSlider";
import UiNotifications from "../pages/Ui/ui-notifications";
import UiToast from "../pages/Ui/UiToast";
import UiOffCanvas from "../pages/Ui/UiOffCanvas";
import UiPlaceholders from "../pages/Ui/UiPlaceholders";

//Pages
import PagesStarter from "../pages/Utility/pages-starter";
import PagesMaintenance from "../pages/Utility/pages-maintenance";
import PagesComingsoon from "../pages/Utility/pages-comingsoon";
import PagesTimeline from "../pages/Utility/pages-timeline";
import PagesFaqs from "../pages/Utility/pages-faqs";
import PagesPricing from "../pages/Utility/pages-pricing";
import Pages404 from "../pages/Utility/pages-404";
import Pages500 from "../pages/Utility/pages-500";

//user
import UserAdd from "../pages/User/UserAdd";
import UserList from "../pages/User/UserList";

//user-group
import GroupAdd from "../pages/UserGroup/GroupAdd";
import GroupList from "../pages/UserGroup/GroupList";

//partner
import PartnerAdd from "../pages/Partner/PartnerAdd";
import Permissions from "pages/UserGroup/Permissions";
import ChangePassword from "pages/Authentication/ChangePassword";
import PartnerList from "pages/Partner/PartnerList";
import CountryAdd from "pages/Country/CountryAdd";
import CountryList from "pages/Country/CountryList";
import StateAdd from "pages/State/StateAdd";
import StateList from "pages/State/StateList";
import CityAdd from "pages/City/CityAdd";
import CityList from "pages/City/CityList";
import Upload from "pages/Data/Upload";
import UploadHistory from "pages/Data/UploadHistory";
import Profile from "pages/UserProfile/Profile";
import ChangeStatus from "pages/Data/ChangeStatus";
import HomePage from "pages/HomePage/index";
import ForgetPassword from "pages/Authentication/ForgetPassword";

const publicRoutes = [
  { path: "/", exact: true, component: HomePage },
  { path: "/forgot-password", exact: true, component: ForgetPassword },
  { path: "/forgot-password/:token", exact: true, component: ForgetPassword },
  { path: "/logout", component: Logout },
  { path: "/panel/login", component: Login },
  { path: "/panel/pages-maintenance", component: PagesMaintenance },
  { path: "/panel/pages-comingsoon", component: PagesComingsoon },
  { path: "/panel/pages-404", component: Pages404 },
  { path: "/panel/pages-500", component: Pages500 },
];

const authProtectedRoutes = [
  { path: "/panel/dashboard", component: Dashboard },

  //profile
  { path: "/panel/user/profile", component: Profile },

  //User
  { path: "/panel/user/add", component: UserAdd },
  { path: "/panel/user/list", component: UserList },
  { path: "/panel/user/add/:id", component: UserAdd },
  { path: "/panel/user/changepassword", component: ChangePassword },

  //User
  { path: "/panel/usergroup/add", component: GroupAdd },
  { path: "/panel/usergroup/list", component: GroupList },
  { path: "/panel/usergroup/add/:id", component: GroupAdd },
  { path: "/panel/usergroup/permission", component: Permissions },
  ///Partner
  { path: "/panel/partner/add", component: PartnerAdd },
  { path: "/panel/partner/add/:id", component: PartnerAdd },
  { path: "/panel/partner/list", component: PartnerList },
  //Country
  { path: "/panel/country/add", component: CountryAdd },
  { path: "/panel/country/add/:id", component: CountryAdd },
  { path: "/panel/country/list", component: CountryList },

  //State
  { path: "/panel/state/add", component: StateAdd },
  { path: "/panel/state/add/:id", component: StateAdd },
  { path: "/panel/state/list", component: StateList },
  //City
  { path: "/panel/city/add", component: CityAdd },
  { path: "/panel/city/add/:id", component: CityAdd },
  { path: "/panel/city/list", component: CityList },

  //upload-data
  { path: "/panel/upload/data", component: Upload },
  { path: "/panel/upload/history", component: UploadHistory },
  { path: "/panel/upload/history/:id", component: UploadHistory },
  { path: "/panel/upload/change-status", component: ChangeStatus },

  // Tables
  { path: "/panel/tables-basic", component: BasicTables },
  { path: "/panel/tables-datatable", component: DatatableTables },
  { path: "/panel/tables-responsive", component: ResponsiveTables },
  { path: "/panel/tables-editable", component: EditableTables },
  { path: "/panel/tables-dragndrop", component: DragDropTables },

  // Forms
  { path: "/panel/form-elements", component: FormElements },
  { path: "/panel/form-layouts", component: FormLayouts },
  { path: "/panel/form-advanced", component: FormAdvanced },
  { path: "/panel/form-editors", component: FormEditors },
  { path: "/panel/form-mask", component: FormMask },
  { path: "/panel/form-repeater", component: FormRepeater },
  { path: "/panel/form-uploads", component: FormUpload },
  { path: "/panel/form-wizard", component: FormWizard },
  { path: "/panel/form-validation", component: FormValidations },
  { path: "/panel/form-xeditable", component: FormXeditable },
  { path: "/panel/dual-listbox", component: DualListbox },

  // Ui
  { path: "/panel/ui-alerts", component: UiAlert },
  { path: "/panel/ui-buttons", component: UiButtons },
  { path: "/panel/ui-cards", component: UiCards },
  { path: "/panel/ui-carousel", component: UiCarousel },
  { path: "/panel/ui-colors", component: UiColors },
  { path: "/panel/ui-dropdowns", component: UiDropdown },
  { path: "/panel/ui-general", component: UiGeneral },
  { path: "/panel/ui-grid", component: UiGrid },
  { path: "/panel/ui-images", component: UiImages },
  { path: "/panel/ui-lightbox", component: UiLightbox },
  { path: "/panel/ui-modals", component: UiModal },
  { path: "/panel/ui-progressbars", component: UiProgressbar },
  { path: "/panel/ui-sweet-alert", component: UiSweetAlert },
  { path: "/panel/ui-tabs-accordions", component: UiTabsAccordions },
  { path: "/panel/ui-typography", component: UiTypography },
  { path: "/panel/ui-video", component: UiVideo },
  { path: "/panel/ui-session-timeout", component: UiSessionTimeout },
  { path: "/panel/ui-rating", component: UiRating },
  { path: "/panel/ui-rangeslider", component: UiRangeSlider },
  { path: "/panel/ui-notifications", component: UiNotifications },
  { path: "/panel/ui-toasts", component: UiToast },
  { path: "/panel/ui-offcanvas", component: UiOffCanvas },
  { path: "/panel/ui-placeholders", component: UiPlaceholders },
  //Utility
  { path: "/panel/pages-starter", component: PagesStarter },
  { path: "/panel/pages-timeline", component: PagesTimeline },
  { path: "/panel/pages-faqs", component: PagesFaqs },
  { path: "/panel/pages-pricing", component: PagesPricing },

  // this route should be at the end of all other routes
  // eslint-disable-next-line react/display-name
  {
    path: "/panel/",
    exact: true,
    component: () => <Redirect to="/dashboard" />,
  },
];

export { authProtectedRoutes, publicRoutes };
