import React, { useEffect, useState } from "react";
import ReactApexChart from "react-apexcharts";

const DataGraph = props => {
  const [series, setSeries] = useState([]);
  const [options, setOptions] = useState({});
  useEffect(() => {
    if (props.data) {
      const chartsData = [
        {
          name: "Pending",
          data: [],
        },
        {
          name: "Hold",
          data: [],
        },
        {
          name: "Close",
          data: [],
        },
      ];
      const chartOption = {
        chart: {
          toolbar: {
            show: false,
          },
        },
        plotOptions: {
          bar: {
            horizontal: false,
            columnWidth: "45%",
            endingShape: "rounded",
          },
        },
        dataLabels: {
          enabled: false,
        },
        stroke: {
          show: true,
          width: 2,
          colors: ["transparent"],
        },

        colors: ["#2e82f3", "#FFA500", "#FF0000"],
        xaxis: {
          categories: [],
        },
        yaxis: {
          title: {
            text: "Record",
          },
        },
        grid: {
          borderColor: "#f1f1f1",
        },
        fill: {
          opacity: 1,
        },
        tooltip: {
          y: {
            formatter: function (val) {
              // console.log(val);
              return val + `${val == "1" ? ` Record` : ` Records`}`;
            },
          },
        },
      };

      props.data.forEach(item => {
        chartsData[0].data.push(item.totalPending);
        chartsData[1].data.push(item.totalHold);
        chartsData[2].data.push(item.totalClose);
        chartOption.xaxis.categories.push(`${item.monName}-${item.yr}`);
      });
      setSeries(chartsData);
      setOptions(chartOption);
    }
  }, [props]);

  return (
    <React.Fragment>
      <ReactApexChart
        options={options}
        series={series}
        type="bar"
        height={350}
        className="apex-charts"
      />
    </React.Fragment>
  );
};

export default DataGraph;
