import Loader from "components/Common/Loader";
import TableView from "components/Common/TableView";
import useFilter from "components/Common/useFilter";
import {
  encrypt,
  getFormId,
  parseJwt,
  urlValidation,
} from "helpers/common_helper";
import React, { useEffect, useState } from "react";
import { selectFilter, textFilter } from "react-bootstrap-table2-filter";
import { getChangeStatusListAction, getPartnerAction } from "store/actions";
import ChangeStatusModal from "./ChangeStatusModal";
import Papa from "papaparse";
import ChangeStatusHisModal from "./ChangeStatusHisModal";
import { toast } from "react-toastify";

const ChangeStatus = props => {
  const formId = getFormId();
  const auth = urlValidation(formId);
  const [record, setRecord] = useState({});
  const [modalOpen, setModalOpen] = useState(false);
  const [modalHistory, setModalHistory] = useState(false);
  const [userTokenDel, setUserTokenDel] = useState({});
  const [partnerOption, setPartnerOption] = useState([]);
  const [filter, handelFilter] = useFilter("", 7);
  const [selectedPartner, setSelectedPartner] = useState("");
  const [products, setProducts] = useState([]);
  const [serverPagination, setServerPagination] = useState({
    totalItems: "",
    currentPage: "",
    itemsPerPage: "",
    totalPages: "",
    hasNextPage: "",
  });

  const filterFields = [
    {
      dataField: "RegNo",
      text: "Reg. No.",
      placeholder: "Registration No.",
    },
    { dataField: "Make", text: "Make" },
    { dataField: "POS", text: "POS" },
    { dataField: "ChasisNumber", text: "Chasis No." },
    { dataField: "EngNo", text: "Engine NO." },
    {
      dataField: "stage",
      text: "Stage",
      filter: [
        { value: "", label: "All" },
        { value: "pending", label: "Pending" },
        { value: "close", label: "Close" },
        { value: "hold", label: "Hold" },
      ],
      defaultValue: "",
    },
  ];

  const columns = [
    {
      dataField: "id",
      text: "Id",
      sort: true,
      formatter: (cell, row, rowIndex) => {
        return rowIndex + 1;
      },
    },
    {
      dataField: "RegNo",
      text: "Registration Number",
      sort: true,
    },
    {
      dataField: "CusName",
      text: "Customer Name",
    },
    {
      dataField: "LoanNo",
      text: "Loan Number",
    },
    {
      dataField: "Make",
      text: "Make",
    },
    {
      dataField: "EMI",
      text: "EMI",
    },
    {
      dataField: "POS",
      text: "POS",
    },
    {
      dataField: "ChasisNumber",
      text: "Chasis Number",
    },
    {
      dataField: "EngNo",
      text: "Engine Number",
    },
    {
      dataField: "Bucket",
      text: "Bucket",
    },
    {
      dataField: "1stConfirmerName",
      text: "1st Confirmer Name",
    },
    {
      dataField: "2ndConfirmerName",
      text: "2nd Confirmer Name",
    },
    {
      dataField: "3rdConfirmerName",
      text: "3rd Confirmer Name",
    },
    {
      dataField: "1stConfirmerNo",
      text: "1st Confirmer No",
    },
    {
      dataField: "2ndConfirmerNo",
      text: "2nd Confirmer No",
    },
    {
      dataField: "3rdConfirmerNo",
      text: "3rd Confirmer No",
    },
    {
      dataField: "Branch",
      text: "Branch",
    },
    {
      dataField: "Model",
      text: "Model",
    },
    {
      dataField: "Sec17",
      text: "Sec17",
    },
    {
      dataField: "Seasoning",
      text: "Seasoning",
    },
    {
      dataField: "TBR",
      text: "TBR",
    },
    {
      dataField: "stage",
      text: "Stage",
      formatter: (cell, row) => {
        {
          const stage = row.stage;
          let badgeStyle;
          if (stage === "pending") {
            badgeStyle = { background: "#2e82f3" };
          } else if (stage === "close") {
            badgeStyle = { background: "#FF0000" };
          } else {
            badgeStyle = { background: "#FFA500" };
          }
          return (
            <span className="badge" style={badgeStyle}>
              {stage}
            </span>
          );
        }
      },
    },
    {
      dataField: "isLive",
      text: "Is Live",
      formatter: isLive => {
        {
          return isLive ? (
            <span className=" badge bg-success">Live</span>
          ) : (
            <span className="badge bg-danger btn-rounded">Pending</span>
          );
        }
      },
    },

    {
      dataField: "status",
      text: "Status",
      sort: true,
      formatter: data => {
        return data === 1 ? (
          <span className=" badge bg-success mx-3">Active</span>
        ) : (
          <span className="badge bg-danger btn-rounded mx-3">Inactive</span>
        );
      },
    },
  ];

  useEffect(async () => {
    try {
      let authUser = localStorage.getItem("authUser");
      authUser = authUser ? JSON.parse(authUser) : {};

      if (authUser) {
        let authToken = authUser?.authToken || "";
        if (authToken) {
          const userDetails = parseJwt(authToken);
          setUserTokenDel(userDetails);
          if (!userDetails.masterId) {
            props.setLoading(true);
            const response = await getPartnerAction();
            if (response.status === 1) {
              let arr = response?.data || [];
              userTokenDel.masterId ? (arr = [arr]) : arr;
              setSelectedPartner(arr[0].id);
              setPartnerOption(arr);
              props.setLoading(false);
            } else {
              props.setLoading(false);
              // toast.error(response2.msg);
            }
          } else {
            setSelectedPartner(userDetails.masterId);
          }
        }
      }
    } catch (error) {
      console.log(error);
      props.setLoading(false);
    }
  }, []);

  useEffect(async () => {
    try {
      if (selectedPartner && !modalOpen) {
        props.setLoading(true);
        const encodedQueryString = encrypt(filter);
        const response = await getChangeStatusListAction(
          userTokenDel.masterId ? "" : selectedPartner,
          encodedQueryString
        );
        if (response.status === 1) {
          let arr = response?.data || [];
          setServerPagination({ ...response.metadata });
          setProducts(response.data);
          props.setLoading(false);
        } else {
          props.setLoading(false);
          // toast.error(response2.msg);
        }
      }
    } catch (error) {
      props.setLoading(false);
      console.log(error);
      let err = error?.response?.data?.msg || "Something went wrong";
      toast.error(err);
    }
  }, [selectedPartner, filter, modalOpen]);

  const handlePartnerChange = partnerId => {
    handelFilter({ ...filter, pagination: { ...filter.pagination, page: 1 } });
    setSelectedPartner(partnerId);
  };

  function downloadCSV(data) {
    let csv = Papa.unparse(data);
    let csvData = new Blob([csv], { type: "text/csv;charset=utf-8;" });
    let csvURL = null;
    if (navigator.msSaveBlob) {
      csvURL = navigator.msSaveBlob(csvData, `sample.csv`);
    } else {
      csvURL = window.URL.createObjectURL(csvData);
    }

    let tempLink = document.createElement("a");
    tempLink.href = csvURL;
    tempLink.setAttribute("download", "ExportData.csv");
    tempLink.click();
  }

  const handleEdit = row => {
    setRecord(row);
    setModalOpen(!modalOpen);
  };
  const handleView = row => {
    setRecord(row);
    setModalHistory(!modalHistory);
  };
  const handleExport = async () => {
    try {
      const fit = JSON.parse(JSON.stringify({ ...filter, exports: true }));
      const encodedQueryString = encrypt(fit);
      const response = await getChangeStatusListAction(
        selectedPartner,
        encodedQueryString
      );
      if (response.status == 1) {
        if (response.data.length > 0) {
          let dataObj = response.data.map(val => {
            delete val.masterId;
            delete val.id;
            return val;
          });
          downloadCSV(dataObj);
        }
      }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div className="page-content">
      <TableView
        tableName="Edit Record"
        columns={columns}
        products={products}
        filterData={{ filter, handelFilter }}
        Pagination={serverPagination}
        sortDate={{ form: filter.from, to: filter.to }}
        partner={
          !userTokenDel.masterId && {
            option: partnerOption,
            handleChange: handlePartnerChange,
            defaultPartner: selectedPartner,
          }
        }
        editAction={auth.allowEdit ? handleEdit : ""}
        viewAction={auth.allowView && handleView}
        exportAction={handleExport}
        filterFields={filterFields}
      />
      <ChangeStatusModal
        modalOpen={modalOpen}
        setModalOpen={setModalOpen}
        record={record}
        setLoading={props.setLoading}
        formId={formId}
      />
      <ChangeStatusHisModal
        modalOpen={modalHistory}
        setModalOpen={setModalHistory}
        record={record}
        setLoading={props.setLoading}
      />
    </div>
  );
};

export default Loader(ChangeStatus);
