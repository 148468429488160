import ConfirmModel from "components/Common/ConfirmModel";
import TableView from "components/Common/TableView";
import useFilter from "components/Common/useFilter";
import { encrypt } from "helpers/common_helper";
import React, { useEffect, useState } from "react";
import { textFilter } from "react-bootstrap-table2-filter";
import { useLocation, useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import {
  Alert,
  Button,
  Card,
  CardBody,
  Col,
  Form,
  FormGroup,
  Input,
  Label,
  Modal,
  Row,
} from "reactstrap";
import {
  deleteUploadAction,
  getUploadAction,
  saveUploadAction,
  uploadDataLiveAction,
} from "store/actions";

const PrevUpload = props => {
  const { id, auth, formId } = props;
  const [isOpen, setIsOpen] = useState(false);
  const [deleteRowId, setDeleteRowId] = useState("");
  const [products, setProducts] = useState([]);
  const [headerDetails, setHeaderDetails] = useState({});
  const [editRow, setEditRow] = useState({});
  const [uploadError, setUploadError] = useState("");
  const [errorModel, setErrorModel] = useState(true);
  const location = useLocation();
  const history = useHistory();
  const [confirmModelPre, setConfModelPro] = useState({
    modelMessage: "",
    confirmationState: false,
    modelOpen: false,
  });
  const [filter, handelFilter] = useFilter(6);
  const [serverPagination, setServerPagination] = useState({
    totalItems: "",
    currentPage: "",
    itemsPerPage: "",
    totalPages: "",
    hasNextPage: "",
  });

  const fetchData = async (id, filter) => {
    try {
      props.setLoading(true);
      const encodedQueryString = encrypt(filter);

      const response1 = await getUploadAction(id, encodedQueryString);
      if (response1.status === 1) {
        setServerPagination({ ...response1.metadata });
        props.setLoading(false);
        let arr = response1?.data || [];
        setProducts(arr);
      } else if (response1.status === -1) {
        let err = response1?.msg || "";
        setUploadError(err);
        props.setLoading(false);
      } else {
        toast.error(response1.msg);
        props.setLoading(false);
      }
    } catch (error) {
      console.log(error);
      props.setLoading(false);
      let err = error?.response?.data?.msg || "Something went wrong";
      toast.error(err);
    }
  };

  useEffect(async () => {
    if (id) {
      await fetchData(id, filter);
    }
    setHeaderDetails(location.state.row);
  }, [id, location, filter]);
  const handleEdit = async row => {
    setEditRow(row);
    setIsOpen(true);
  };
  const tableButton = {
    text: "Upload Data",
    path: "upload/data",
  };

  const filterFields = [
    {
      dataField: "RegNo",
      text: "Reg. No.",
      placeholder: "Registration No.",
    },
    { dataField: "Make", text: "Make" },
    { dataField: "POS", text: "POS" },
    { dataField: "ChasisNumber", text: "Chasis No." },
    { dataField: "EngNo", text: "Engine No." },
  ];
  const columns = [
    {
      dataField: "id",
      text: "Id",
      sort: true,
      formatter: (cell, row, rowIndex) => {
        return rowIndex + 1;
      },
    },
    {
      dataField: "RegNo",
      text: "Registration Number",
      sort: true,
    },
    {
      dataField: "CusName",
      text: "Customer Name",
    },
    {
      dataField: "LoanNo",
      text: "Loan Number",
    },
    {
      dataField: "Make",
      text: "Make",
    },
    {
      dataField: "EMI",
      text: "EMI",
    },
    {
      dataField: "POS",
      text: "POS",
    },
    {
      dataField: "ChasisNumber",
      text: "Chasis Number",
    },
    {
      dataField: "EngNo",
      text: "Engine Number",
    },
    {
      dataField: "Bucket",
      text: "Bucket",
    },
    {
      dataField: "1stConfirmerName",
      text: "1st Confirmer Name",
    },
    {
      dataField: "2ndConfirmerName",
      text: "2nd Confirmer Name",
    },
    {
      dataField: "3rdConfirmerName",
      text: "3rd Confirmer Name",
    },
    {
      dataField: "1stConfirmerNo",
      text: "1st Confirmer No",
    },
    {
      dataField: "2ndConfirmerNo",
      text: "2nd Confirmer No",
    },
    {
      dataField: "3rdConfirmerNo",
      text: "3rd Confirmer No",
    },
    {
      dataField: "Branch",
      text: "Branch",
    },
    {
      dataField: "Model",
      text: "Model",
    },
    {
      dataField: "Sec17",
      text: "Sec17",
    },
    {
      dataField: "Seasoning",
      text: "Seasoning",
    },
    {
      dataField: "TBR",
      text: "TBR",
    },
  ];
  if (!uploadError) {
    columns.push(
      {
        dataField: "isLive",
        text: "Is Live",
        formatter: isLive => {
          {
            return isLive ? (
              <span className=" badge bg-success">Live</span>
            ) : (
              <span className="badge bg-danger btn-rounded">Pending</span>
            );
          }
        },
      },

      {
        dataField: "status",
        text: "Status",
        sort: true,
        formatter: data => {
          return data === 1 ? (
            <span className=" badge bg-success mx-3">Active</span>
          ) : (
            <span className="badge bg-danger btn-rounded mx-3">Inactive</span>
          );
        },
      }
    );
  }
  const dateFormatter = dataTime => {
    return dataTime.slice(0, 10).split("-").reverse().join("/");
  };
  const handleDelete = async row => {
    setDeleteRowId(row.id);
    setConfModelPro({
      modelMessage: `Are you want to delete ${row.RegNo} this row`,
      modelOpen: true,
    });
  };
  const handleConfState = async stateValue => {
    try {
      setConfModelPro({
        ...confirmModelPre,
        confirmationState: stateValue,
        modelOpen: false,
      });
      const filterProduct = products.filter(val => val.id !== deleteRowId);
      if (stateValue) {
        props.setLoading(true);
        const response = await deleteUploadAction(
          {
            formId: formId,
          },
          deleteRowId
        );
        if (response.status === 1) {
          setProducts(filterProduct);
          props.setLoading(false);
          toast.success(response.msg);
          handelFilter({
            ...filter,
            pagination: {
              page: 1,
            },
          });
        } else {
          toast.error(response.msg);
          props.setLoading(false);
        }
      }
    } catch (error) {
      props.setLoading(false);
      let err = error?.response?.data?.msg || "Something went wrong";
      toast.error(err);
    }
  };
  const handleClose = modelState => {
    setConfModelPro({ ...confirmModelPre, modelOpen: modelState });
  };

  const handleLive = async () => {
    props.setLoading(false);
    try {
      const response = await uploadDataLiveAction(
        {
          formId: formId,
        },
        id
      );
      if (response.status === 1) {
        props.setLoading(false);
        await fetchData(id, filter);
        toast.success(response.msg);
        history.push("/panel/upload/history");
      } else {
        toast.error(response.msg);
        props.setLoading(false);
      }
    } catch (error) {
      props.setLoading(false);
      let err = error?.response?.data?.msg || "Something went wrong";
      toast.error(err);
    }
  };
  const handleChange = e => {
    const { name, value } = e.target;
    setEditRow({ ...editRow, [name]: value });
  };
  const handleSave = async () => {
    props.setLoading(false);
    try {
      const { id: rowId } = editRow;
      let uploadRowData = {
        "Registration Number": editRow.RegNo,
        "Customer Name": editRow.CusName,
        "Loan No": editRow.LoanNo,
        Make: editRow.Make,
        EMI: editRow.EMI,
        POS: editRow.POS,
        "Chasis Number": editRow.ChasisNumber,
        "Engine Number": editRow.EngNo,
        Bucket: editRow.Bucket,
        "1st Confirmer Name": editRow["1stConfirmerName"],
        "2nd Confirmer Name": editRow["2ndConfirmerName"],
        "3rd Confirmer Name": editRow["3rdConfirmerName"],
        "1st Confirmer No": editRow["1stConfirmerNo"],
        "2nd Confirmer No": editRow["2ndConfirmerNo"],
        "3rd Confirmer No": editRow["3rdConfirmerNo"],
        Branch: editRow.Branch,
        Model: editRow.Model,
        "Sec 17": editRow["Sec17"],
        Seasoning: editRow.Seasoning,
        TBR: editRow.TBR,
        Allocation: editRow.Allocation,
        Address: editRow.Address,
        status: editRow.status,
        id: editRow.id,
        masterId: editRow.masterId,
      };
      const response = await saveUploadAction(
        {
          file: [uploadRowData],
          partner: headerDetails.partnerId,
          formId: formId,
        },
        rowId
      );
      if (response.status === 1) {
        props.setLoading(false);
        await fetchData(id, filter);
        setIsOpen(!isOpen);
        toast.success(response.msg);
      } else {
        toast.error(response.msg);
        props.setLoading(false);
      }
    } catch (error) {
      console.log(error);
      props.setLoading(false);
      let err = error?.response?.data?.msg || "Something went wrong";
      toast.error(err);
    }
  };
  return (
    <div>
      <Row>
        <Col sm="12">
          <Card>
            <CardBody>
              <Row>
                <Col className="my-2" sm="12" md="6" lg="3">
                  <span>Filename :</span>
                  <span className="fw-bold"> {headerDetails.filename}</span>
                </Col>
                <Col className="my-2" sm="12" md="6" lg="3">
                  <span>Category :</span>
                  <span className="fw-bold"> {headerDetails.name}</span>
                </Col>
                <Col className="my-2" sm="12" md="6" lg="3">
                  <span>Partner :</span>
                  <span className="fw-bold"> {headerDetails.companyName}</span>
                </Col>
                <Col className="my-2" sm="12" md="6" lg="3">
                  <span>User Name :</span>
                  <span className="fw-bold"> {headerDetails.userName}</span>
                </Col>
                <Col className="my-2" sm="12" md="6" lg="3">
                  <span>Upload Date :</span>
                  <span className="fw-bold">
                    {headerDetails.createdAt &&
                      dateFormatter(headerDetails.createdAt)}
                  </span>
                </Col>
                <Col className="my-2" sm="12" md="6" lg="3">
                  <span>is Live :</span>
                  <span className="fw-bold">
                    {" "}
                    {headerDetails.isLive ? (
                      <span className=" badge bg-success">Live</span>
                    ) : (
                      <span className="badge bg-danger btn-rounded">
                        Pending
                      </span>
                    )}
                  </span>
                </Col>
                {headerDetails.isLive == "1" && (
                  <Col className="my-2" sm="12" md="6" lg="3">
                    <span>live Date :</span>
                    <span className="fw-bold">
                      {headerDetails.liveDate &&
                        dateFormatter(headerDetails.liveDate)}
                    </span>
                  </Col>
                )}
              </Row>
            </CardBody>
          </Card>
        </Col>
      </Row>
      {confirmModelPre.modelOpen && (
        <ConfirmModel
          confirmationStateAction={handleConfState}
          message={confirmModelPre.modelMessage}
          closeAction={handleClose}
        />
      )}
      <Modal
        isOpen={isOpen}
        role="dialog"
        autoFocus={true}
        data-toggle="modal"
        size="xl"
      >
        <div className="modal-content">
          <div className="modal-header border-bottom-0">
            <button
              type="button"
              className="btn-close"
              onClick={() => {
                setIsOpen(false);
              }}
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>
          </div>
          <div className="modal-body">
            <div className="text-center mb-4">
              <div className="row justify-content-center">
                <div className="col-xl-10">
                  <h4 className="text-primary mb-3">Edit Data</h4>
                  <div className="mt-4 row text-start">
                    <Form>
                      <Row>
                        <Col md="6">
                          <FormGroup>
                            <Label for="RegNo">Registration Number</Label>
                            <Input
                              id="RegNo"
                              name="RegNo"
                              placeholder="Registration Number"
                              type="text"
                              onChange={handleChange}
                              value={editRow.RegNo}
                            />
                          </FormGroup>
                        </Col>
                        <Col md="6">
                          <FormGroup>
                            <Label for="CusName">Customer Name</Label>
                            <Input
                              id="CusName"
                              name="CusName"
                              placeholder="Customer Name"
                              type="text"
                              onChange={handleChange}
                              value={editRow.CusName}
                            />
                          </FormGroup>
                        </Col>
                      </Row>
                      <Row>
                        <Col md="6">
                          <FormGroup>
                            <Label for="LoanNo">Loan Number</Label>
                            <Input
                              id="LoanNo"
                              name="LoanNo"
                              placeholder="Loan Number"
                              type="text"
                              onChange={handleChange}
                              value={editRow.LoanNo}
                            />
                          </FormGroup>
                        </Col>
                        <Col md="6">
                          <FormGroup>
                            <Label for="Make">Make</Label>
                            <Input
                              id="Make"
                              name="Make"
                              placeholder="Make"
                              type="text"
                              onChange={handleChange}
                              value={editRow.Make}
                            />
                          </FormGroup>
                        </Col>
                      </Row>
                      <Row>
                        <Col md="6">
                          <FormGroup>
                            <Label for="EMI">EMI</Label>
                            <Input
                              id="EMI"
                              name="EMI"
                              placeholder="EMI"
                              type="text"
                              onChange={handleChange}
                              value={editRow.EMI}
                            />
                          </FormGroup>
                        </Col>
                        <Col md="6">
                          <FormGroup>
                            <Label for="POS">POS</Label>
                            <Input
                              id="POS"
                              name="POS"
                              placeholder="POS"
                              type="text"
                              onChange={handleChange}
                              value={editRow.POS}
                            />
                          </FormGroup>
                        </Col>
                      </Row>
                      <Row>
                        <Col md="6">
                          <FormGroup>
                            <Label for="ChasisNumber">Chasis Number</Label>
                            <Input
                              id="ChasisNumber"
                              name="ChasisNumber"
                              placeholder="Chasis Number"
                              type="text"
                              onChange={handleChange}
                              value={editRow.ChasisNumber}
                            />
                          </FormGroup>
                        </Col>
                        <Col md="6">
                          <FormGroup>
                            <Label for="EngNo">Engine Number</Label>
                            <Input
                              id="EngNo"
                              name="EngNo"
                              placeholder="Engine Number"
                              type="text"
                              onChange={handleChange}
                              value={editRow.EngNo}
                            />
                          </FormGroup>
                        </Col>
                      </Row>
                      <Row>
                        <Col md="6">
                          <FormGroup>
                            <Label for="Bucket">Bucket</Label>
                            <Input
                              id="Bucket"
                              name="Bucket"
                              placeholder="Bucket"
                              type="text"
                              onChange={handleChange}
                              value={editRow.Bucket}
                            />
                          </FormGroup>
                        </Col>
                        <Col md="6">
                          <FormGroup>
                            <Label for="1stConfirmerName">
                              1st Confirmer Name
                            </Label>
                            <Input
                              id="1stConfirmerName"
                              name="1stConfirmerName"
                              placeholder="1st Confirmer Name"
                              type="text"
                              onChange={handleChange}
                              value={editRow["1stConfirmerName"]}
                            />
                          </FormGroup>
                        </Col>
                      </Row>
                      <Row>
                        <Col md="6">
                          <FormGroup>
                            <Label for="2ndConfirmerName">
                              2nd Confirmer Name
                            </Label>
                            <Input
                              id="2ndConfirmerName"
                              name="2ndConfirmerName"
                              placeholder="2nd Confirmer Name"
                              type="text"
                              onChange={handleChange}
                              value={editRow["2ndConfirmerName"]}
                            />
                          </FormGroup>
                        </Col>
                        <Col md="6">
                          <FormGroup>
                            <Label for="3rdConfirmerName">
                              3rd Confirmer Name
                            </Label>
                            <Input
                              id="3rdConfirmerName"
                              name="3rdConfirmerName"
                              placeholder="3rd Confirmer Name"
                              type="text"
                              onChange={handleChange}
                              value={editRow["3rdConfirmerName"]}
                            />
                          </FormGroup>
                        </Col>
                      </Row>
                      <Row>
                        <Col md="6">
                          <FormGroup>
                            <Label for="1stConfirmerNo">1st Confirmer No</Label>
                            <Input
                              id="1stConfirmerNo"
                              name="1stConfirmerNo"
                              placeholder="1st Confirmer No"
                              type="text"
                              onChange={handleChange}
                              value={editRow["1stConfirmerNo"]}
                            />
                          </FormGroup>
                        </Col>
                        <Col md="6">
                          <FormGroup>
                            <Label for="2ndConfirmerNo">2nd Confirmer No</Label>
                            <Input
                              id="2ndConfirmerNo"
                              name="2ndConfirmerNo"
                              placeholder="2nd Confirmer No"
                              type="text"
                              onChange={handleChange}
                              value={editRow["2ndConfirmerNo"]}
                            />
                          </FormGroup>
                        </Col>
                      </Row>
                      <Row>
                        <Col md="6">
                          <FormGroup>
                            <Label for="3rdConfirmerNo">3rd Confirmer No</Label>
                            <Input
                              id="3rdConfirmerNo"
                              name="3rdConfirmerNo"
                              placeholder="3rd Confirmer No"
                              type="text"
                              onChange={handleChange}
                              value={editRow["3rdConfirmerNo"]}
                            />
                          </FormGroup>
                        </Col>
                        <Col md="6">
                          <FormGroup>
                            <Label for="Branch">Branch</Label>
                            <Input
                              id="Branch"
                              name="Branch"
                              placeholder="Branch"
                              type="text"
                              onChange={handleChange}
                              value={editRow.Branch}
                            />
                          </FormGroup>
                        </Col>
                      </Row>
                      <Row>
                        <Col md="6">
                          <FormGroup>
                            <Label for="Model">Model</Label>
                            <Input
                              id="Model"
                              name="Model"
                              placeholder="Model"
                              type="text"
                              onChange={handleChange}
                              value={editRow.Model}
                            />
                          </FormGroup>
                        </Col>
                        <Col md="6">
                          <FormGroup>
                            <Label for="Sec17">Sec17</Label>
                            <Input
                              id="Sec17"
                              name="Sec17"
                              placeholder="Sec17"
                              type="text"
                              onChange={handleChange}
                              value={editRow.Sec17}
                            />
                          </FormGroup>
                        </Col>
                      </Row>
                      <Row>
                        <Col md="6">
                          <FormGroup>
                            <Label for="Seasoning">Seasoning</Label>
                            <Input
                              id="Seasoning"
                              name="Seasoning"
                              placeholder="Seasoning"
                              type="text"
                              onChange={handleChange}
                              value={editRow.Seasoning}
                            />
                          </FormGroup>
                        </Col>
                        <Col md="6">
                          <FormGroup>
                            <Label for="TBR">TBR</Label>
                            <Input
                              id="TBR"
                              name="TBR"
                              placeholder="TBR"
                              type="text"
                              onChange={handleChange}
                              value={editRow.TBR}
                            />
                          </FormGroup>
                        </Col>
                      </Row>
                      <Row>
                        <div className="mt-4">
                          <h5 className="font-size-14 mb-1">
                            Status<span className=" text-danger"> *</span>
                          </h5>
                          <div className="d-flex">
                            <div className="form-check me-2">
                              <Input
                                className="form-check-input"
                                type="radio"
                                name="status"
                                id="active"
                                value="1"
                                checked={editRow.status == 1}
                                onChange={handleChange}
                              />
                              <label
                                className="form-check-label"
                                htmlFor="active"
                              >
                                Active
                              </label>
                            </div>
                            <div className="form-check">
                              <Input
                                className="form-check-input"
                                type="radio"
                                name="status"
                                id="inactive"
                                value="0"
                                checked={editRow.status == 0}
                                onChange={handleChange}
                              />
                              <label
                                className="form-check-label"
                                htmlFor="inactive"
                              >
                                Inactive
                              </label>
                            </div>
                          </div>
                        </div>
                      </Row>
                    </Form>
                  </div>
                  <div className="my-2">
                    <Button color="primary" onClick={handleSave}>
                      Save
                    </Button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Modal>

      {uploadError ? (
        <TableView
          tableName={"Duplicate Record found List"}
          columns={columns}
          products={uploadError}
          filterData={{ filter, handelFilter }}
          Pagination={serverPagination}
        />
      ) : (
        <TableView
          tableName={"Upload Record List"}
          columns={columns}
          products={products}
          filterData={{ filter, handelFilter }}
          Pagination={serverPagination}
          sortDate={{ form: filter.from, to: filter.to }}
          editRowAction={auth.allowEdit ? handleEdit : ""}
          deleteRowAction={auth.allowDelete ? handleDelete : ""}
          buttonAdd={auth.allowCreate ? tableButton : ""}
          filterFields={filterFields}
        />
      )}

      {auth.allowCreate && !uploadError ? (
        <Row className="ms-3 mb-5">
          <Col sm="12">
            <div className="d-flex align-items-center">
              <Button size="lg" color="primary" onClick={handleLive}>
                Live Data
              </Button>
            </div>
          </Col>
        </Row>
      ) : (
        ""
      )}
    </div>
  );
};

export default PrevUpload;
