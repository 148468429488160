import React, { useState } from "react";

function formatDate(date, removeMonth = 0, removeDays = 0) {
  const d = new Date(date);
  d.setMonth(d.getMonth() - removeMonth);
  d.setDate(d.getDate() - removeDays);

  const month = String(d.getMonth() + 1).padStart(2, "0");
  const day = String(d.getDate()).padStart(2, "0");
  const year = d.getFullYear();

  return [year, month, day].join("-");
}

const useFilter = (month = "", days = "") => {
  const currentDate = new Date();
  const initialFromDate = formatDate(currentDate, month, days);
  const initialToDate = formatDate(currentDate);

  const [filter, setFilter] = useState({
    pagination: { page: 1, sizePerPage: 10 },
    searchText: {},
    from: initialFromDate,
    to: initialToDate,
    sortField: "id",
    sortOrder: "asc",
  });

  const handelFilter = data => {
    setFilter(data);
  };
  return [filter, handelFilter];
};

export default useFilter;
