import React from "react";

const CustomCloseButton = ({ onClick }) => {
  return (
    <span className="custom-close-button" onClick={onClick}>
      <span className="material-icons-outlined">close</span>
    </span>
  );
};

export default CustomCloseButton;
