import React, { useEffect, useState } from "react";
import { Field, Form, Formik } from "formik";
import * as Yup from "yup";
import {
  Alert,
  Button,
  Card,
  CardBody,
  Col,
  Container,
  FormGroup,
  Label,
  Row,
} from "reactstrap";
import {
  getMasterTypeListAction,
  getPartnerAction,
  saveUploadAction,
} from "store/actions";
import Loader from "components/Common/Loader";
import { csvValidation, getFormId, parseJwt } from "helpers/common_helper";
import Papa from "papaparse";
import { toast } from "react-toastify";
import { AiOutlineDownload } from "react-icons/ai";

const Upload = props => {
  let csvJson = [
    {
      "Registration Number": "",
      "Customer Name": "",
      "Loan No": "",
      Make: "",
      EMI: "",
      POS: "",
      "Chasis Number": "",
      "Engine Number": "",
      Bucket: "",
      "1st Confirmer Name": "",
      "2nd Confirmer Name": "",
      "3rd Confirmer Name": "",
      "1st Confirmer No": "",
      "2nd Confirmer No": "",
      "3rd Confirmer No": "",
      Branch: "",
      Model: "",
      "Sec 17": "",
      Seasoning: "",
      TBR: "",
      Allocation: "",
      Address: "",
    },
  ];
  const initialValues = {
    partner: "",
    category: "",
    file: "",
  };
  const [values, setValues] = useState(initialValues);
  const [partnerOption, setPartnerOption] = useState([]);
  const [category, setCategory] = useState([]);
  const [filename, setFilename] = useState("");
  const [userTokenDel, setUserTokenDel] = useState({});

  const validationSchema = Yup.object({
    category: Yup.string().required("select type"),
    partner: Yup.string().required("select partner"),
    file: Yup.mixed()
      .required("File is required")
      .test(
        "fileType",
        "Only CSV and Excel files are allowed",
        value => value && /(\.csv|\.xlsx|\.xls)$/i.test(value.name)
      ),
  });

  function downloadCSV() {
    var csv = Papa.unparse(csvJson);

    var csvData = new Blob([csv], { type: "text/csv;charset=utf-8;" });
    var csvURL = null;
    if (navigator.msSaveBlob) {
      csvURL = navigator.msSaveBlob(csvData, "sample.csv");
    } else {
      csvURL = window.URL.createObjectURL(csvData);
    }

    var tempLink = document.createElement("a");
    tempLink.href = csvURL;
    tempLink.setAttribute("download", "sample.csv");
    tempLink.click();
  }

  useEffect(async () => {
    props.setLoading(true);
    let authUser = localStorage.getItem("authUser");
    authUser = authUser ? JSON.parse(authUser) : {};

    if (authUser) {
      let authToken = authUser?.authToken || "";
      if (authToken) {
        const userDetails = parseJwt(authToken);
        setUserTokenDel(userDetails);
        if (userDetails.masterId) {
          setValues({
            ...values,
            partner: userDetails.masterId,
          });
        }
      }
    }
  }, []);

  useEffect(async () => {
    if (Object.keys(userTokenDel).length > 0) {
      try {
        let response1 = await getMasterTypeListAction("", "1004");
        if (response1.status === 1) {
          props.setLoading(false);
          let arr = response1?.data || [];
          setCategory(arr);
        } else {
          toast.error(response1.msg);
          props.setLoading(false);
        }

        const response2 = !userTokenDel.masterId && (await getPartnerAction());
        if (response2.status === 1) {
          let arr = response2?.data || [];
          userTokenDel.masterId ? (arr = [arr]) : arr;
          setPartnerOption(arr);
          props.setLoading(false);
        } else {
          props.setLoading(false);
          // toast.error(response2.msg);
        }
      } catch (error) {
        props.setLoading(false);
        let err = error?.response?.data?.msg || "Something went wrong";
        toast.error(err);
      }
    }
  }, [userTokenDel]);

  const convertFileToBase64 = file => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();

      reader.onloadend = () => {
        const base64String = reader.result;
        resolve(base64String);
      };

      reader.onerror = error => {
        reject(error);
      };

      reader.readAsDataURL(file);
    });
  };

  const handleSubmit = async (values, actions) => {
    if (values.file) {
      const base64File = await convertFileToBase64(values.file);
      values.base64File = base64File;
    }
    try {
      props.setLoading(true);
      let response = await saveUploadAction({
        ...values,
        file: filename,
        formId: getFormId(),
      });
      if (response.status === 1) {
        props.setLoading(false);
        actions.resetForm();
        toast.success(response.msg);
      } else {
        toast.error(response.msg);
      }
    } catch (error) {
      console.log(error);
      props.setLoading(false);
      let err = error?.response?.data?.msg || "Something went wrong";
      toast.error(err);
    }
  };

  const handleChange = (event, setFieldValue) => {
    const file = event?.currentTarget?.files[0] || null;
    setFieldValue("file", file);
    setFilename(file.name);
  };
  return (
    <div className="page-content mb-4">
      <Container>
        <Row>
          <Col sm="12">
            <Card>
              <CardBody>
                <Formik
                  initialValues={values}
                  validationSchema={validationSchema}
                  enableReinitialize={true}
                  onSubmit={handleSubmit}
                >
                  {({ errors, touched, setFieldValue, values }) => (
                    <Form>
                      <Row>
                        {!userTokenDel.masterId && (
                          <Col md="6">
                            <FormGroup>
                              <Label for="partner">
                                Select Partner
                                <span className=" text-danger"> *</span>
                              </Label>
                              <Field
                                as="select"
                                name="partner"
                                id="partner"
                                className={
                                  "form-select" +
                                  (errors.parent && touched.parent
                                    ? " is-invalid"
                                    : "")
                                }
                                value={values.partner}
                                //   disabled={userTokenDel.masterId && true}
                              >
                                <option value={""}>Select partner</option>
                                {partnerOption.map(value => (
                                  <option value={value.id} key={value.id}>
                                    {value.companyName}
                                  </option>
                                ))}
                              </Field>
                              {errors.partner && touched.partner ? (
                                <p className="text-danger mt-1">
                                  {errors.partner}
                                </p>
                              ) : null}
                            </FormGroup>
                          </Col>
                        )}
                        <Col md="6">
                          <FormGroup>
                            <Label for="category">
                              Select Category
                              <span className=" text-danger"> *</span>
                            </Label>
                            <Field
                              as="select"
                              name="category"
                              id="category"
                              value={values.category}
                              className={
                                "form-select" +
                                (errors.category && touched.category
                                  ? " is-invalid"
                                  : "")
                              }
                            >
                              <option value={""}>Select master type</option>
                              {category.map(value => (
                                <option value={value.id} key={value.id}>
                                  {value.name}
                                </option>
                              ))}
                            </Field>
                            {errors.category && touched.category ? (
                              <p className="text-danger mt-1">
                                {errors.category}
                              </p>
                            ) : null}
                          </FormGroup>
                        </Col>
                        <Col md="6">
                          <FormGroup>
                            <Label for="file">
                              Upload File
                              <span className=" text-danger"> *</span>
                            </Label>
                            <input
                              type="file"
                              name="file"
                              id="file"
                              accept=".csv,.xlsx,.xls"
                              onChange={e => handleChange(e, setFieldValue)}
                              placeholder="Select file"
                              maxLength={15}
                              className={"form-control"}
                              value={values.file ? values.file.filename : ""}
                            />
                            {errors.file && touched.file ? (
                              <p className="text-danger mt-1">{errors.file}</p>
                            ) : null}
                          </FormGroup>
                        </Col>
                        <Col md="6">
                          <FormGroup>
                            <div className="d-flex mt-3">
                              <div className=" ms-0 m-2">
                                <Button
                                  id="download"
                                  onClick={downloadCSV}
                                  color="primary"
                                  className="me-2"
                                >
                                  <AiOutlineDownload />
                                </Button>
                                <Label for="download">Format CSV File</Label>
                              </div>
                            </div>
                          </FormGroup>
                        </Col>
                      </Row>
                      <Row>
                        <Col xs={{ size: 2, offset: 5 }}>
                          <Button color="primary" type="submit">
                            Save
                          </Button>
                        </Col>
                      </Row>
                    </Form>
                  )}
                </Formik>
              </CardBody>
            </Card>
          </Col>
        </Row>
        <Alert color="danger">
          {`Please ensure that your CSV file includes data in either "Registration Number", "Chasis Number", or "Engine Number" columns for each record. Incorrect or missing data in these columns may result in displaying inaccurate information after file upload. Follow the specified file format to avoid any issues.`}
        </Alert>
      </Container>
    </div>
  );
};

export default Loader(Upload);
