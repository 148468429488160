import React from "react";
import { useParams } from "react-router-dom";
import PrevUpload from "./PrevUpload";
import UploadList from "./UploadList";
import { getFormId, urlValidation } from "helpers/common_helper";
import Loader from "components/Common/Loader";

const UploadHistory = props => {
  const { id } = useParams();
  const formId = getFormId();
  const auth = urlValidation(formId);
  return (
    <div className="page-content">
      {!id ? (
        <UploadList formId={formId} auth={auth} {...props} />
      ) : (
        <PrevUpload id={id} formId={formId} auth={auth} {...props} />
      )}
    </div>
  );
};

export default Loader(UploadHistory);
