import PropTypes from "prop-types";
import React, { Component, useEffect, useState } from "react";
import {
  Alert,
  Card,
  CardBody,
  Col,
  Container,
  FormGroup,
  Label,
  Row,
} from "reactstrap";

// Redux
import { connect } from "react-redux";
import { Link, withRouter } from "react-router-dom";

import { Formik, Field, Form, ErrorMessage } from "formik";
import * as Yup from "yup";

// action
import {
  userForgetPassword,
  userForgetPasswordSuccess,
  userForgetPasswordError,
  forgetPwd,
} from "../../store/actions";

// import images
import profile from "../../assets/images/profile-img.png";
import logo from "../../assets/images/logo.svg";
import { useParams, useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import Countdown, { zeroPad } from "react-countdown";
import usePasswordToggle from "hooks/usePasswordToggle";

const ForgetPasswordPage = props => {
  const { token } = useParams();
  const history = useHistory();
  const [countdown, setCountdown] = useState("0000");
  const [passIcon, passInputType] = usePasswordToggle();
  const [cIcon, CInputType] = usePasswordToggle();
  useEffect(async () => {
    try {
      if (token) {
        const response = await forgetPwd({ token: token });
        if (response.status == 1) {
          setCountdown(
            new Date(response.data.exp * 1000).getTime() - new Date().getTime()
          );
        } else {
          toast.error(response.msg);
          history.push("/");
        }
      }
    } catch (error) {
      console.log(error);
      let err = error?.response?.data?.msg || "Something went wrong";
      toast.error(err);
      history.push("/");
    }
  }, [token]);

  const handleResetPwd = async (values, action) => {
    try {
      const { password } = values;
      const response = await forgetPwd({ token: token, password: password });
      if (response.status == 1) {
        toast.success(response.msg);
        action.resetForm();
        history.push("/");
      } else {
        toast.error(response.msg);
        history.push("/");
      }
    } catch (error) {
      console.log(error);
      let err = error?.response?.data?.msg || "Something went wrong";
      toast.error(err);
      history.push("/");
    }
  };
  return (
    <React.Fragment>
      {token ? (
        <div>
          <div className="home-btn d-none d-sm-block">
            <Link to="/" className="text-dark">
              <i className="bx bx-home h2" />
            </Link>
          </div>
          <div className="account-pages my-5 pt-sm-5">
            <Container>
              <Row className="justify-content-center">
                <Col md={8} lg={6} xl={5}>
                  <Card className="overflow-hidden">
                    <div className="d-flex flex-column align-items-center py-3">
                      <div className="h5">URL Expire IN</div>
                      <Countdown
                        date={Date.now() + countdown}
                        renderer={props => (
                          <div className=" border border-2 rounded-2 py-1 px-2 fs-4">
                            {zeroPad(props.minutes)}:{zeroPad(props.seconds)}
                          </div>
                        )}
                      />
                    </div>
                    <div className="bg-primary bg-soft">
                      <Row>
                        <Col className="col-7">
                          <div className="text-primary p-4">
                            <h5 className="text-primary">Forget Password</h5>
                            <p>Generate Your password</p>
                          </div>
                        </Col>
                        <Col className="col-5 align-self-end">
                          <img src={profile} alt="" className="img-fluid" />
                        </Col>
                      </Row>
                    </div>
                    <CardBody className="pt-0">
                      <div>
                        <Link to="/">
                          <div className="avatar-md profile-user-wid mb-4">
                            <span className="avatar-title rounded-circle bg-light">
                              <img
                                src={logo}
                                alt=""
                                className="rounded-circle"
                                height="34"
                              />
                            </span>
                          </div>
                        </Link>
                      </div>
                      <div className="p-2">
                        <Formik
                          enableReinitialize={true}
                          initialValues={{
                            password: "",
                            cPassword: "",
                          }}
                          validationSchema={Yup.object().shape({
                            password: Yup.string()
                              .min(6, "password minimum 6 characters contain")
                              .required("Enter password"),
                            cPassword: Yup.string()
                              .required("Enter same password")
                              .oneOf(
                                [Yup.ref("password"), null],
                                "Passwords must match"
                              ),
                          })}
                          onSubmit={handleResetPwd}
                        >
                          {({ errors, status, touched, values }) => (
                            <Form className="form-horizontal">
                              <div className="mb-3">
                                <Row>
                                  <Col md="12">
                                    <FormGroup>
                                      <Label for="password">
                                        Password{" "}
                                        <span className=" text-danger">*</span>
                                      </Label>
                                      <Field
                                        type={passInputType}
                                        name="password"
                                        id="password"
                                        placeholder="Enter password"
                                        maxLength={25}
                                        style={{
                                          backgroundImage: "none",
                                        }}
                                        className={
                                          "form-control" +
                                          (errors.password && touched.password
                                            ? " is-invalid"
                                            : "")
                                        }
                                      />
                                      {values.password && (
                                        <span className="password-toggle-icon">
                                          {passIcon}
                                        </span>
                                      )}
                                      <ErrorMessage
                                        name="password"
                                        component="div"
                                        className="invalid-feedback"
                                      />
                                    </FormGroup>
                                  </Col>
                                  <Col md="12">
                                    <FormGroup>
                                      <Label for="cPassword">
                                        Confirm password
                                        <span className=" text-danger"> *</span>
                                      </Label>
                                      <Field
                                        type={CInputType}
                                        name="cPassword"
                                        id="cPassword"
                                        placeholder="Confirm Your password"
                                        maxLength={25}
                                        style={{
                                          backgroundImage: "none",
                                        }}
                                        className={
                                          "form-control" +
                                          (errors.cPassword && touched.cPassword
                                            ? " is-invalid"
                                            : "")
                                        }
                                      />
                                      {values.cPassword && (
                                        <span className="password-toggle-icon">
                                          {cIcon}
                                        </span>
                                      )}
                                      <ErrorMessage
                                        name="cPassword"
                                        component="div"
                                        className="invalid-feedback"
                                      />
                                    </FormGroup>
                                  </Col>
                                </Row>
                              </div>
                              <div className="text-end">
                                <button
                                  className="btn btn-primary w-md"
                                  type="submit"
                                >
                                  Save
                                </button>
                              </div>
                            </Form>
                          )}
                        </Formik>
                      </div>
                    </CardBody>
                  </Card>
                  <div className="mt-5 text-center">
                    <p>
                      Go back to{" "}
                      <Link to="/" className="fw-medium text-primary">
                        Login
                      </Link>{" "}
                    </p>
                    <p>
                      © {new Date().getFullYear()} Adciasis. Design by{" "}
                      <a
                        href="https://adciasis.com/"
                        target="new"
                        title="Adciasis"
                      >
                        Adciasis
                      </a>
                    </p>
                  </div>
                </Col>
              </Row>
            </Container>
          </div>
        </div>
      ) : (
        <div>
          <div className="home-btn d-none d-sm-block">
            <Link to="/" className="text-dark">
              <i className="bx bx-home h2" />
            </Link>
          </div>
          <div className="account-pages my-5 pt-sm-5">
            <Container>
              <Row className="justify-content-center">
                <Col md={8} lg={6} xl={5}>
                  <Card className="overflow-hidden">
                    <div className="bg-primary bg-soft">
                      <Row>
                        <Col className="col-7">
                          <div className="text-primary p-4">
                            <h5 className="text-primary">Forget Password !</h5>
                            <p>For Generate new Password</p>
                          </div>
                        </Col>
                        <Col className="col-5 align-self-end">
                          <img src={profile} alt="" className="img-fluid" />
                        </Col>
                      </Row>
                    </div>
                    <CardBody className="pt-0">
                      <div>
                        <Link to="/">
                          <div className="avatar-md profile-user-wid mb-4">
                            <span className="avatar-title rounded-circle bg-light">
                              <img
                                src={logo}
                                alt=""
                                className="rounded-circle"
                                height="34"
                              />
                            </span>
                          </div>
                        </Link>
                      </div>
                      <div className="p-2">
                        {props.forgetError && props.forgetError ? ( // Extract the error message from the object
                          <Alert color="danger" style={{ marginTop: "13px" }}>
                            {props.forgetError}
                          </Alert>
                        ) : null}
                        {props.forgetSuccessMsg ? (
                          <Alert color="success" style={{ marginTop: "13px" }}>
                            {props.forgetSuccessMsg}
                          </Alert>
                        ) : null}

                        <Formik
                          enableReinitialize={true}
                          initialValues={{
                            email: "",
                          }}
                          validationSchema={Yup.object().shape({
                            email: Yup.string()
                              .email("Invalid email")
                              .required("Please Enter email"),
                          })}
                          onSubmit={(values, action) => {
                            props.userForgetPassword(values, props.history);
                            action.resetForm();
                          }}
                        >
                          {({ errors, status, touched }) => (
                            <Form className="form-horizontal">
                              <div className="mb-3">
                                <Label for="email" className="form-label">
                                  Email
                                </Label>
                                <Field
                                  name="email"
                                  type="text"
                                  style={{
                                    backgroundImage: "none",
                                  }}
                                  className={
                                    "form-control" +
                                    (errors.email && touched.email
                                      ? " is-invalid"
                                      : "")
                                  }
                                />
                                <ErrorMessage
                                  name="email"
                                  component="div"
                                  className="invalid-feedback"
                                />
                              </div>
                              <div className="text-end">
                                <button
                                  className="btn btn-primary w-md"
                                  type="submit"
                                >
                                  Reset
                                </button>
                              </div>
                            </Form>
                          )}
                        </Formik>
                      </div>
                    </CardBody>
                  </Card>
                  <div className="mt-5 text-center">
                    <p>
                      Go back to{" "}
                      <Link to="/" className="fw-medium text-primary">
                        Login
                      </Link>{" "}
                    </p>
                    <p>
                      © {new Date().getFullYear()} Adciasis. Design by{" "}
                      <a
                        href="https://adciasis.com/"
                        target="new"
                        title="Adciasis"
                      >
                        Adciasis
                      </a>
                    </p>
                  </div>
                </Col>
              </Row>
            </Container>
          </div>
        </div>
      )}
    </React.Fragment>
  );
};

ForgetPasswordPage.propTypes = {
  forgetError: PropTypes.string, // Update the prop type to string
  forgetSuccessMsg: PropTypes.object,
  history: PropTypes.object,
  userForgetPassword: PropTypes.func, // Update the prop type to func
};

const mapStateToProps = state => {
  const { forgetError, forgetSuccessMsg } = state.ForgetPassword;
  return { forgetError, forgetSuccessMsg };
};

export default withRouter(
  connect(mapStateToProps, { userForgetPassword })(ForgetPasswordPage)
);
