import React, { useEffect, useState } from "react";

import {
  Modal,
  ModalHeader,
  ModalBody,
  Container,
  Row,
  Col,
  Card,
} from "reactstrap";
import CustomCloseButton from "./CustomCloseButton";
import { toast } from "react-toastify";

// action
import { getMasterTypeListAction } from "../../store/actions";
import Loader from "components/Common/Loader";

function MemberShipModal(props) {
  const logo =
    "https://recovery.newitzone.com/api/public/assets/images/Logo2.png";
  const [modal, setModal] = useState(false);
  const [cardData, setCardData] = useState([]);
  const toggle = () => {
    setModal(!modal);
    props.isToggle();
  };

  useEffect(() => {
    setModal(props.isOpen);
  }, [props.isOpen]);
  useEffect(async () => {
    if (modal) {
      try {
        const response1 = await getMasterTypeListAction("", "1005");
        if (response1.status === 1) {
          props.setLoading(false);
          let arr = response1?.data || [];
          setCardData(arr);
        } else {
          toast.error(response1.msg);
          props.setLoading(false);
        }
      } catch (error) {
        props.setLoading(false);
        let err = error?.response?.data?.msg || "Something went wrong";
        toast.error(err);
      }
    }
  }, [modal]);

  const handlePaymentProcess = () => {
    toggle();
    props.otherModal.paymentToggle();
  };
  return (
    <div>
      <Modal
        isOpen={modal}
        toggle={toggle}
        backdrop={false}
        centered
        className="homepage-modal membership-modal"
        size="lg"
      >
        <ModalHeader
          toggle={toggle}
          close={<CustomCloseButton onClick={toggle} />}
          className="header"
        >
          <img src={logo} alt="logo" className="brand-logo" />
        </ModalHeader>
        <ModalBody className="pb-5">
          <Container fluid className="">
            <Row className="justify-content-center">
              <Col>
                <Row>
                  <Col>
                    <div className="text-primary py-4">
                      <div className="h2 text-center">Subscription Plan</div>
                    </div>
                    <div className="mt-2 sub-summary">
                      Lorem Ipsum is simply dummy text of the printing and
                      typesetting industry. Lorem Ipsum has been the
                      {`industry's`}
                      standard dummy text ever since the 1500s, when an unknown.
                    </div>
                  </Col>
                </Row>
                <div className="mt-2 row plans-cards mx-auto">
                  {cardData.map((item, index) => {
                    const { otherDetails: data } = item;
                    return (
                      <div
                        className="col-12 col-sm-6 col-lg-4 col-xl-3 col-xxl"
                        key={index.id}
                      >
                        <Card body className={`${data.colorId}`}>
                          <div className="plan-count ">
                            <span className="month">
                              {data.month >= 12 ? data.month / 12 : data.month}
                            </span>{" "}
                            <span>{data.month >= 12 ? "Year" : "Month"}</span>
                          </div>
                          {data.dispercent && (
                            <div className="discount-banner">
                              Up to {data.dispercent}% off
                            </div>
                          )}
                          <div className="rate-banner">
                            <div className="rupee-sign me-2">&#x20B9;</div>
                            <div className="amount">{data.rate}</div>
                          </div>
                          <div className="content">{data.dis}</div>
                          {!props.offer && (
                            <div className="btn" onClick={handlePaymentProcess}>
                              Pay Now
                            </div>
                          )}
                        </Card>
                      </div>
                    );
                  })}
                </div>
              </Col>
            </Row>
          </Container>
        </ModalBody>
      </Modal>
    </div>
  );
}

export default Loader(MemberShipModal);
