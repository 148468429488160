import React from "react";
import img1 from "../../assets/images/homepage/Choose1.png";
import img2 from "../../assets/images/homepage/Choose2.png";
import img3 from "../../assets/images/homepage/Choose3.png";
import img4 from "../../assets/images/homepage/Choose4.png";
import { Col, Container, Row } from "reactstrap";

const ChooseUs = () => {
  return (
    <Container fluid className="px-xl-5 mb-5 choose">
      <div className="text-center">
        <div className="choose-header mx-auto">
          <h3 className="underline">Why Choose Us</h3>
          <p>
            We are committed to delivering exceptional debt recovery services
            while prioritizing client satisfaction, transparency, and data
            security.
          </p>
          <br />
          <h5>Here are some reasons worth considering:</h5>
        </div>
        <div className=" m-0 p-0 choose-cards">
          <div className="w-50">
            <div className="card">
              <img src={img1} alt="firstcard" className="card-img-top" />
              <div className="card-body">
                <h6>Unauthorised Repo Charges</h6>
                <p className="card-text">
                  We safeguards against unauthorized repo charges by
                  implementing stringent controls and monitoring mechanisms. Our
                  platform ensures transparency and accountability, minimizing
                  the risk of unauthorized fees and charges.
                </p>
              </div>
            </div>
          </div>
          <div className="w-50">
            <div className="card">
              <img src={img2} alt="firstcard" className="card-img-top" />
              <div className="card-body">
                <h6>Recovery Agent Tracking</h6>
                <p className="card-text">
                  You can easily track and monitor the activities of your
                  recovery agents. Our system provides real-time updates on
                  agent actions, ensuring transparency and enabling you to
                  maintain control and accountability throughout the recovery
                  process.
                </p>
              </div>
            </div>
          </div>
          <div className="w-50">
            <div className="card">
              <img src={img3} alt="firstcard" className="card-img-top" />
              <div className="card-body">
                <h6>Data Analysis</h6>
                <p className="card-text">
                  We leverages advanced data analytics tools to gain valuable
                  insights into debtor behavior, payment patterns, and recovery
                  trends. By analyzing this data, we can optimize strategies and
                  enhance recovery rates, increasing your chances of successful
                  debt collection.
                </p>
              </div>
            </div>
          </div>
          <div className="w-50">
            <div className="card">
              <img src={img4} alt="firstcard" className="card-img-top" />
              <div className="card-body">
                <h6>Data Security</h6>
                <p className="card-text">
                  We prioritize the highest standards of data security. Our
                  platform employs robust security measures to protect sensitive
                  information, ensuring confidentiality and compliance with data
                  protection regulations. You can trust that your data is safe
                  and secure with us.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Container>
  );
};

export default ChooseUs;
