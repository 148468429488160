import React, { useEffect, useState } from "react";

import {
  Modal,
  ModalHeader,
  ModalBody,
  Container,
  Row,
  Col,
  Alert,
  Label,
} from "reactstrap";
import CustomCloseButton from "./CustomCloseButton";
// Redux

import { Formik, Field, Form, ErrorMessage } from "formik";
import * as Yup from "yup";

import "yup-phone";
import Loader from "components/Common/Loader";
import usePasswordToggle from "hooks/usePasswordToggle";
import { savePartnerRegAction } from "store/actions";
import { toast } from "react-toastify";
import { RotatingLines } from "react-loader-spinner";

function SignUpModal(props) {
  const logo =
    "https://recovery.newitzone.com/api/public/assets/images/Logo2.png";
  const [modal, setModal] = useState(false);
  const [loading, setLoading] = useState(false);

  const [passIcon, passInputType] = usePasswordToggle();
  const [cIcon, CInputType] = usePasswordToggle();

  const toggle = () => {
    setModal(!modal);
    props.isToggle();
  };

  useEffect(() => {
    setModal(props.isOpen);
  }, [props.isOpen]);

  const validationSchema = Yup.object().shape({
    email: Yup.string()
      .email("Please provide a valid email address.")
      .required("Please enter your email address."),
    name: Yup.string().required("Please enter your full name."),
    mobileNo: Yup.string()
      .required("Please enter your phone number.")
      .phone("IN", false, "Please provide a valid phone number."),
    pass: Yup.string()
      .min(8, "Password must be at least 8 characters long.")
      .required("Please enter a password."),
    confirmPass: Yup.string()
      .required("Please confirm your password.")
      .oneOf(
        [Yup.ref("pass"), null],
        "Passwords do not match. Please re-enter."
      ),
  });
  const handleSubmit = async (values, actions) => {
    try {
      setLoading(true);
      const response = await savePartnerRegAction(values);
      if (response.status === 1) {
        setLoading(false);
        actions.resetForm();
        // toast.success(response.msg);
        toggle();
        props.otherModal.OTPVerifyToggle();
      } else {
        toast.error(response.msg);
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
      let err = error?.response?.data?.msg || "Something went wrong";
      toast.error(err);
    }
  };
  return (
    <div>
      <Modal
        isOpen={modal}
        toggle={toggle}
        backdrop={false}
        centered
        className="homepage-modal"
        size="custom"
      >
        <ModalHeader
          toggle={toggle}
          close={<CustomCloseButton onClick={toggle} />}
          className="header"
        >
          <img src={logo} alt="logo" className="brand-logo" />
        </ModalHeader>
        <ModalBody className="pb-5">
          <Container className="px-3">
            <Row className="justify-content-center">
              <Col>
                <Row>
                  <Col>
                    <div className="text-primary py-4">
                      <div className="h2">Sign Up</div>
                    </div>
                  </Col>
                </Row>
                <div className="p-2">
                  <Formik
                    enableReinitialize={true}
                    initialValues={{
                      name: "",
                      email: "",
                      mobileNo: "",
                      pass: "",
                      confirmPass: "",
                    }}
                    validationSchema={validationSchema}
                    onSubmit={handleSubmit}
                  >
                    {({ errors, status, touched, values }) => (
                      <Form className="form-horizontal">
                        <div className="mb-3">
                          <Label for="name" className="form-label">
                            Full Name
                          </Label>
                          <Field
                            name="name"
                            type="text"
                            style={{
                              backgroundImage: "none",
                            }}
                            placeholder="Name"
                            className={
                              "form-control" +
                              (errors.name && touched.name ? " is-invalid" : "")
                            }
                          />
                          <ErrorMessage
                            name="name"
                            component="div"
                            className="invalid-feedback"
                          />
                        </div>
                        <div className="mb-3">
                          <Label for="email" className="form-label">
                            Email
                          </Label>
                          <Field
                            name="email"
                            type="text"
                            style={{
                              backgroundImage: "none",
                            }}
                            placeholder="Email"
                            className={
                              "form-control" +
                              (errors.email && touched.email
                                ? " is-invalid"
                                : "")
                            }
                          />
                          <ErrorMessage
                            name="email"
                            component="div"
                            className="invalid-feedback"
                          />
                        </div>
                        <div className="mb-3">
                          <Label for="mobileNo" className="form-label">
                            Phone Number
                          </Label>
                          <Field
                            name="mobileNo"
                            type="text"
                            style={{
                              backgroundImage: "none",
                            }}
                            placeholder="Phone Number"
                            onKeyPress={event => {
                              if (!/[0-9]/.test(event.key)) {
                                event.preventDefault();
                              }
                            }}
                            maxLength="12"
                            className={
                              "form-control" +
                              (errors.mobileNo && touched.mobileNo
                                ? " is-invalid"
                                : "")
                            }
                          />
                          <ErrorMessage
                            name="mobileNo"
                            component="div"
                            className="invalid-feedback"
                          />
                        </div>
                        <div className="mb-3 position-relative">
                          <Label for="pass" className="form-label">
                            Create Password
                          </Label>
                          <div className="input-group auth-pass-inputgroup">
                            <Field
                              name="pass"
                              type={passInputType}
                              autoComplete="true"
                              style={{
                                backgroundImage: "none",
                              }}
                              placeholder="Password"
                              className={
                                "form-control" +
                                (errors.pass && touched.pass
                                  ? " is-invalid"
                                  : "")
                              }
                            />
                            {values.pass && (
                              <span className="password-toggle-icon">
                                {passIcon}
                              </span>
                            )}

                            {errors.pass && (
                              <span className="invalid-feedback">
                                {errors.pass}
                              </span>
                            )}
                          </div>
                        </div>
                        <div className="mb-3 position-relative">
                          <Label for="confirmPass" className="form-label">
                            Confirm Password
                          </Label>
                          <div className="input-group auth-pass-inputgroup">
                            <Field
                              name="confirmPass"
                              type={CInputType}
                              style={{
                                backgroundImage: "none",
                              }}
                              placeholder="Password"
                              className={
                                "form-control" +
                                (errors.confirmPass && touched.confirmPass
                                  ? " is-invalid"
                                  : "")
                              }
                            />
                            {values.confirmPass && (
                              <span className="password-toggle-icon">
                                {cIcon}
                              </span>
                            )}

                            {errors.confirmPass && (
                              <span className="invalid-feedback">
                                {errors.confirmPass}
                              </span>
                            )}
                          </div>
                        </div>

                        <div className="my-2 text-end form-label">
                          Already Have an Account?{" "}
                          <span
                            className="signIn-btn"
                            onClick={() => {
                              toggle();
                              props.otherModal.logintoggle();
                            }}
                          >
                            Sign In Now!
                          </span>
                        </div>
                        <div className="mt-3 d-grid">
                          <button
                            className={`btn btn-block btn-danger ${
                              loading ? "disabled" : ""
                            }`}
                          >
                            {loading ? (
                              <RotatingLines
                                strokeColor="white"
                                strokeWidth="5"
                                animationDuration="0.75"
                                width="35"
                                visible={true}
                              />
                            ) : (
                              "Sign Up"
                            )}
                          </button>
                        </div>
                      </Form>
                    )}
                  </Formik>
                </div>
              </Col>
            </Row>
          </Container>
        </ModalBody>
      </Modal>
    </div>
  );
}

export default Loader(SignUpModal);
