import React, { useEffect, useState } from "react";
import logo from "../../assets/images/homepage/logo2.png";

import {
  Modal,
  ModalHeader,
  ModalBody,
  Container,
  Row,
  Col,
  Card,
} from "reactstrap";
import CustomCloseButton from "./CustomCloseButton";

import Loader from "components/Common/Loader";
import OtpInput from "react-otp-input";
import Countdown, { zeroPad } from "react-countdown";
import { RotatingLines } from "react-loader-spinner";

function OTPVerificationModal(props) {
  const [modal, setModal] = useState(false);
  const [resendOTP, setResendOTP] = useState(true);
  const [countdownTimer, setCountdownTimer] = useState();
  const [errorMsg, setErrorMsg] = useState("");
  const [otp, setOtp] = useState("");
  const [loading, setLoading] = useState(false);
  const toggle = () => {
    setModal(!modal);
    props.isToggle();
  };
  useEffect(() => {
    setCountdownTimer(new Date().getTime() + 60000);
  }, [modal]);

  const handleChange = newOtp => {
    setOtp(newOtp);
    setErrorMsg("");
  };

  useEffect(() => {
    setModal(props.isOpen);
  }, [props.isOpen]);

  const handleVerifyOTP = () => {
    setLoading(true);
    if (otp.length < 4) {
      setOtp("");
      setLoading(false);
      return setErrorMsg("Invalid OTP");
    }
    if (otp != "1234") {
      setOtp("");
      setLoading(false);
      return setErrorMsg("Invalid OTP");
    }
    toggle();
    props.otherModal.membershipToggle();
    setLoading(false);
    setOtp("");
  };

  return (
    <div>
      <Modal
        isOpen={modal}
        toggle={toggle}
        centered
        backdrop={false}
        className="homepage-modal OTPVerification-modal"
        size="lg"
      >
        <ModalHeader
          toggle={toggle}
          close={<CustomCloseButton onClick={toggle} />}
          className="header"
        >
          <img src={logo} alt="logo" />
        </ModalHeader>
        <ModalBody className="py-5">
          <div className="h2 text-center">Verify Your Mobile Number </div>
          <p className="description">
            Please enter the One-Time Password (OTP) sent to your registered
            mobile number
          </p>
          <div className="input-group">
            {errorMsg && <p className="text-danger mt-1 fs-4">{errorMsg}</p>}

            <OtpInput
              value={otp}
              onChange={handleChange}
              numInputs={4}
              renderSeparator={<span></span>}
              inputStyle={{
                width: "45px",
                height: "45px",
                fontSize: "18px",
                margin: "0 5px",
                borderRadius: "4px",
                border: "1px solid #ced4da",
                textAlign: "center",
                padding: "1px",
              }}
              inputType="number"
              shouldAutoFocus={true}
              renderInput={props => <input {...props} />}
            />

            {resendOTP && (
              <div className="count-down">
                <Countdown
                  date={countdownTimer}
                  onComplete={() => {
                    setResendOTP(false);
                  }}
                  renderer={props => (
                    <div className="mt-2 ms-auto fs-4">
                      {zeroPad(props.minutes)}:{zeroPad(props.seconds)}
                    </div>
                  )}
                />
              </div>
            )}
          </div>
          <div className="my-2 text-center form-label">
            If you {`haven't`} received the OTP?
            <span
              className={`btn ms-1 ${resendOTP ? "disabled" : ""}`}
              onClick={() => {
                setCountdownTimer(new Date().getTime() + 60000);
                setResendOTP(true);
              }}
            >
              Resend Again!
            </span>
          </div>
          <div className="d-flex align-items-center">
            <div
              className={`btn ${loading ? "disabled" : ""}`}
              onClick={handleVerifyOTP}
            >
              {loading ? (
                <RotatingLines
                  strokeColor="white"
                  strokeWidth="5"
                  animationDuration="0.75"
                  width="35"
                  visible={true}
                />
              ) : (
                "Verify & Proceed"
              )}
            </div>
          </div>
        </ModalBody>
      </Modal>
    </div>
  );
}

export default Loader(OTPVerificationModal);
