import React from "react";
import offerImg from "../../assets/images/homepage/offer.png";
import { Col, Row } from "reactstrap";

const OfferBanner = props => {
  return (
    <div className="my-5 mt-lg-5 pt-lg-5">
      <div className="my-3 offer-banner px-md-3 px-xl-5">
        <div className="d-flex justify-content-center">
          <img src={offerImg} alt="offerImage" className="" />
        </div>
        <div className="text-white d-flex flex-column justify-content-center py-3 py-lg-0 g-18 ms-xxl-4">
          <h4>Exclusive Deals: Get 30% Off Your First Purchase!</h4>
          <p>
            Browse through our selection of subscriptions at an unmatched price.
            Seize your favorites before {`they're`} no longer available!
          </p>
        </div>
        <div className="d-flex flex-column justify-content-center align-items-center ms-xxl-auto">
          <div className="btn" onClick={props.modal}>
            Avail Offer
          </div>
        </div>
      </div>
    </div>
  );
};

export default OfferBanner;
