import React, { useEffect, useState } from "react";
import AboutUs from "./AboutUs";
import ChooseUs from "./ChooseUs";
import ContactUs from "./ContactUs";
import Features from "./Features";
import Footer from "./Footer";
import Navbar from "./Navbar";
import OfferBanner from "./OfferBanner";
import RequestDemo from "./RequestDemo";
import TestimonialCarousel from "./TestimonialCarousel";
import cardLogo1 from "../../assets/images/homepage/card-logo-1.png";
import cardLogo2 from "../../assets/images/homepage/card-logo-2.png";
import cardLogo3 from "../../assets/images/homepage/card-logo-3.png";
import LoginModal from "./LoginModal";
import {
  Button,
  Card,
  CardText,
  CardTitle,
  Col,
  Container,
  Row,
} from "reactstrap";
import RequestForDemoModal from "./RequestForDemoModal";
import MemberShipModal from "./MemberShipModal";

const index = () => {
  const [isSticky, setIsSticky] = useState(false);
  const [requestDemoModal, setRequestDemoModal] = useState(false);
  const [membershipModal, setMembershipModal] = useState(false);

  const handleScroll = () => {
    if (window.scrollY > 50) {
      setIsSticky(true);
    } else {
      setIsSticky(false);
    }
  };

  const membershipToggle = () => {
    setMembershipModal(!membershipModal);
  };
  const requestDemoToggle = () => {
    setRequestDemoModal(!requestDemoModal);
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <Container
      fluid
      className={`px-0 bg-white homePage ${isSticky ? "sticky" : ""}`}
    >
      <div className="main pb-1 pb-md-5" id="home">
        <div className="navbar-container">
          <Navbar />
          <Container className="border-container my-sm-5">
            <div className="welcome-container">
              <h3 className="underline pb-3">WELCOME</h3>
              <h1 className="my-3">RECOVERYFY</h1>
              <p>
                Simplify the loan recovery process with Recoveryfy&lsquo;s
                user-friendly platform. Track progress, manage accounts, and
                recover debts effortlessly.
              </p>
              <div className="btn mt-3" onClick={requestDemoToggle}>
                Request for Demo
              </div>
            </div>
          </Container>
          <Container fluid className="px-xl-5 pt-4 welcome-container-cards">
            <Card body>
              <CardText className="d-flex align-items-center gap-18">
                <div className="">
                  <img src={cardLogo1} alt="logo1" />
                </div>

                <div>
                  <h2>300+</h2>
                  <p>Total Active Customers</p>
                </div>
              </CardText>
            </Card>

            <Card body>
              <CardText className="d-flex align-items-center gap-18">
                <div className="">
                  <img src={cardLogo2} alt="logo1" />
                </div>

                <div>
                  <h2>15000+</h2>
                  <p>Total Active User</p>
                </div>
              </CardText>
            </Card>

            <Card body>
              <CardText className="d-flex align-items-center gap-18">
                <div className="logo-circle">
                  <img src={cardLogo3} alt="logo1" />
                </div>

                <div>
                  <h2>20+</h2>
                  <p>State Wise Coverage</p>
                </div>
              </CardText>
            </Card>
          </Container>
        </div>
      </div>
      <OfferBanner modal={membershipToggle} />
      <AboutUs />
      <RequestDemo modal={requestDemoToggle} />
      <Features />
      <ChooseUs />
      <TestimonialCarousel />
      <ContactUs />
      <Footer />
      <RequestForDemoModal
        isOpen={requestDemoModal}
        isToggle={requestDemoToggle}
      />
      <MemberShipModal
        isOpen={membershipModal}
        isToggle={membershipToggle}
        offer={true}
      />
    </Container>
  );
};

export default index;
