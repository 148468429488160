import React, { useEffect, useState } from "react";

import {
  Modal,
  ModalHeader,
  ModalBody,
  Container,
  Row,
  Col,
  Alert,
  Label,
} from "reactstrap";
import CustomCloseButton from "./CustomCloseButton";
// Redux

import { Formik, Field, Form, ErrorMessage } from "formik";
import * as Yup from "yup";

import "yup-phone";
import Loader from "components/Common/Loader";
import { saveQueryReqAction } from "store/actions";
import { toast } from "react-toastify";
import { RotatingLines } from "react-loader-spinner";

function RequestForDemoModal(props) {
  const logo =
    "https://recovery.newitzone.com/api/public/assets/images/Logo2.png";
  const [modal, setModal] = useState(false);
  const [loading, setLoading] = useState(false);

  const toggle = () => {
    setModal(!modal);
    props.isToggle();
  };

  useEffect(() => {
    setModal(props.isOpen);
  }, [props.isOpen]);

  const validationSchema = Yup.object().shape({
    email: Yup.string().email("Invalid email").required("Please Enter email"),
    name: Yup.string().required("Please Enter your full name"),
    subject: Yup.string().required("Please Enter subject"),
    message: Yup.string()
      .min(20, "message minimum 20 characters contain")
      .required("Enter Message"),
  });
  const handleSubmit = async (values, actions) => {
    setLoading(true);
    try {
      const response = await saveQueryReqAction({
        ...values,
        requestType: "Request For Demo",
      });
      if (response.status === 1) {
        setLoading(false);
        actions.resetForm();
        toast.success(response.msg);
        props.isToggle();
      } else {
        toast.error(response.msg);
        setLoading(false);
      }
    } catch (error) {
      console.log(error);
      setLoading(false);
      let err = error?.response?.data?.msg || "Something went wrong";
      toast.error(err);
    }
  };
  return (
    <div>
      <Modal
        isOpen={modal}
        toggle={toggle}
        backdrop={false}
        centered
        className="homepage-modal"
        size="custom"
      >
        <ModalHeader
          toggle={toggle}
          close={<CustomCloseButton onClick={toggle} />}
          className="header"
        >
          <img src={logo} alt="logo" className="brand-logo" />
        </ModalHeader>
        <ModalBody className="pb-5">
          <Container className="px-3">
            <Row className="justify-content-center">
              <Col>
                <Row>
                  <Col>
                    <div className="text-primary py-4">
                      <div className="h2">Request For Demo</div>
                    </div>
                  </Col>
                </Row>
                <div className="p-2">
                  <Formik
                    enableReinitialize={true}
                    initialValues={{
                      name: "",
                      email: "",
                      subject: "",
                      message: "",
                    }}
                    validationSchema={validationSchema}
                    onSubmit={handleSubmit}
                  >
                    {({ errors, status, touched, values }) => (
                      <Form className="row">
                        <div className="form-group col-12 col-md-6 my-2 my-md-1 my-lg-2">
                          <label className="mb-1" htmlFor="name">
                            Your Name
                          </label>
                          <Field
                            type="text"
                            name="name"
                            className={
                              "form-control" +
                              (errors.name && touched.name ? " is-invalid" : "")
                            }
                            id="name"
                            aria-describedby="nameHelp"
                            placeholder="Your Name"
                            value={values.name}
                          />
                          {errors.name && touched.name && (
                            <div className="invalid-feedback">
                              {errors.name}
                            </div>
                          )}
                        </div>

                        <div className="form-group col-12 col-md-6 my-2 my-md-1  my-lg-2">
                          <label className="mb-1" htmlFor="email">
                            Your Email
                          </label>
                          <Field
                            type="email"
                            name="email"
                            className={
                              "form-control" +
                              (errors.email && touched.email
                                ? " is-invalid"
                                : "")
                            }
                            id="email"
                            placeholder="Your Email"
                            value={values.email}
                          />
                          {errors.email && touched.email && (
                            <div className="invalid-feedback">
                              {errors.email}
                            </div>
                          )}
                        </div>
                        <div className="form-group col-12 my-2 my-md-1 my-lg-2">
                          <label className="mb-1" htmlFor="subject">
                            Subject
                          </label>
                          <Field
                            type="text"
                            name="subject"
                            className={
                              "form-control" +
                              (errors.subject && touched.subject
                                ? " is-invalid"
                                : "")
                            }
                            id="subject"
                            placeholder="Subject"
                            value={values.subject}
                          />
                          {errors.subject && touched.subject && (
                            <div className="invalid-feedback">
                              {errors.subject}
                            </div>
                          )}
                        </div>
                        <div className="form-group col-12 ">
                          <label className="mb-1" htmlFor="message">
                            Message
                          </label>
                          <Field
                            as="textarea"
                            name="message"
                            className={
                              "form-control" +
                              (errors.message && touched.message
                                ? " is-invalid"
                                : "")
                            }
                            id="message"
                            placeholder="Type your message here..."
                            rows={5}
                            aria-label="With textarea"
                            value={values.message}
                          />
                          {errors.message && touched.message && (
                            <div className="invalid-feedback">
                              {errors.message}
                            </div>
                          )}
                        </div>

                        <div className="mt-3 d-flex">
                          <button
                            className={`btn ms-auto btn-danger ${
                              loading ? "disabled" : ""
                            }`}
                            type="submit"
                          >
                            {loading ? (
                              <RotatingLines
                                strokeColor="white"
                                strokeWidth="5"
                                animationDuration="0.75"
                                width="35"
                                visible={true}
                              />
                            ) : (
                              "Send Request"
                            )}
                          </button>
                        </div>
                      </Form>
                    )}
                  </Formik>
                </div>
              </Col>
            </Row>
          </Container>
        </ModalBody>
      </Modal>
    </div>
  );
}

export default Loader(RequestForDemoModal);
