import TableView from "components/Common/TableView";
import useFilter from "components/Common/useFilter";
import { encrypt } from "helpers/common_helper";
import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { Col, Modal, ModalBody, Row } from "reactstrap";
import { changeStatusHisAction } from "store/actions";

const ChangeStatusHisModal = ({
  modalOpen,
  setModalOpen,
  record,
  setLoading,
}) => {
  const [products, setProducts] = useState([]);
  const [serverPagination, setServerPagination] = useState({
    totalItems: "",
    currentPage: "",
    itemsPerPage: "",
    totalPages: "",
    hasNextPage: "",
  });
  const [filter, handelFilter] = useFilter();

  const toggle = () => {
    setModalOpen(!modalOpen);
  };

  useEffect(async () => {
    if (modalOpen) {
      try {
        if (Object.keys(record).length > 0) {
          const encodedQueryString = encrypt({
            ...filter,
            from: "",
            sortField: "us.createdAt",
          });
          setLoading(true);
          const response = await changeStatusHisAction(
            record.id,
            encodedQueryString
          );
          if (response.status) {
            setLoading(false);
            setServerPagination({ ...response.metadata });
            setProducts(response.data);
          } else {
            setLoading(false);
            toast.success(response.msg);
          }
        }
      } catch (error) {
        setLoading(false);
        console.log(error);
        let err = error?.response?.data?.msg || "Something went wrong";
        toast.error(err);
      }
    }
  }, [record, filter]);

  const columns = [
    {
      dataField: "id",
      text: "Id",
      sort: true,
      formatter: (cell, row, rowIndex) => {
        return rowIndex + 1;
      },
    },
    {
      dataField: "name",
      text: "Username",
    },
    {
      dataField: "code",
      text: "User Code",
    },
    {
      dataField: "stage",
      text: "Stage",
    },
    {
      dataField: "fileUrl",
      text: "Attach File",
      formatter: url => {
        if (url.includes(".pdf")) {
          return (
            <span className="d-flex">
              <a
                href={url}
                target="_blank"
                rel="noopener noreferrer"
                className="fs-6"
              >
                file
              </a>
              <span className="material-icons-round ms-2">picture_as_pdf</span>
            </span>
          );
        } else if (url.includes("/no-image.jpg")) {
          return <span className="d-flex">No attachment</span>;
        } else {
          return (
            <span className="d-flex">
              <a
                href={url}
                target="_blank"
                rel="noopener noreferrer"
                className="fs-6"
              >
                file
              </a>
              <span className="material-icons-round ms-2">
                photo_size_select_actual
              </span>
            </span>
          );
        }
      },
    },
    {
      dataField: "EditDate",
      text: "Edit Date",
      formatter: date => {
        return date.slice(0, 10).split("-").reverse().join("/");
      },
    },
  ];

  return (
    <div>
      <Modal isOpen={modalOpen} toggle={toggle} centered={true} size="lg">
        <div className="modal-header border-bottom-0">
          <button
            type="button"
            className="btn-close"
            onClick={toggle}
            data-bs-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>
        <ModalBody className="py-3 px-5">
          <Row>
            <Col>
              <div className="h4 text-center">Record Details</div>
            </Col>
          </Row>
          <Row className="fs-6">
            <Col lg={4} md={6}>
              <div className="my-1">
                <span className="fw-bold">Registration Number : </span>
                <span>{record.RegNo}</span>
              </div>
            </Col>
            <Col lg={4} md={6}>
              <div className="my-1">
                <span className="fw-bold">Customer Name : </span>
                <span>{record.CusName}</span>
              </div>
            </Col>
            <Col lg={4} md={6}>
              <div className="my-1">
                <span className="fw-bold">Loan Number : </span>
                <span>{record.LoanNo}</span>
              </div>
            </Col>
            <Col lg={4} md={6}>
              <div className="my-1">
                <span className="fw-bold">Make : </span>
                <span>{record.Make}</span>
              </div>
            </Col>
            <Col lg={4} md={6}>
              <div className="my-1">
                <span className="fw-bold">EMI : </span>
                <span>{record.EMI}</span>
              </div>
            </Col>
            <Col lg={4} md={6}>
              <div className="my-1">
                <span className="fw-bold">POS : </span>
                <span>{record.POS}</span>
              </div>
            </Col>
            <Col lg={4} md={6}>
              <div className="my-1">
                <span className="fw-bold">Chasis Number : </span>
                <span>{record.ChasisNumber}</span>
              </div>
            </Col>
            <Col lg={4} md={6}>
              <div className="my-1">
                <span className="fw-bold">Engine Number : </span>
                <span>{record.EngNo}</span>
              </div>
            </Col>
            <Col lg={4} md={6}>
              <div className="my-1">
                <span className="fw-bold">Bucket : </span>
                <span>{record.Bucket}</span>
              </div>
            </Col>
            <Col lg={4} md={6}>
              <div className="my-1">
                <span className="fw-bold">1st Confirmer Name : </span>
                <span>{record["1stConfirmerName"]}</span>
              </div>
            </Col>
            <Col lg={4} md={6}>
              <div className="my-1">
                <span className="fw-bold">2nd Confirmer Name : </span>
                <span>{record["2ndConfirmerName"]}</span>
              </div>
            </Col>
            <Col lg={4} md={6}>
              <div className="my-1">
                <span className="fw-bold">3rd Confirmer Name : </span>
                <span>{record["3rdConfirmerName"]}</span>
              </div>
            </Col>
            <Col lg={4} md={6}>
              <div className="my-1">
                <span className="fw-bold">1st Confirmer No : </span>
                <span>{record["1stConfirmerNo"]}</span>
              </div>
            </Col>
            <Col lg={4} md={6}>
              <div className="my-1">
                <span className="fw-bold">2nd Confirmer No : </span>
                <span>{record["2ndConfirmerNo"]}</span>
              </div>
            </Col>
            <Col lg={4} md={6}>
              <div className="my-1">
                <span className="fw-bold">3rd Confirmer No : </span>
                <span>{record["3rdConfirmerNo"]}</span>
              </div>
            </Col>
            <Col lg={4} md={6}>
              <div className="my-1">
                <span className="fw-bold">Branch : </span>
                <span>{record["Branch"]}</span>
              </div>
            </Col>
            <Col lg={4} md={6}>
              <div className="my-1">
                <span className="fw-bold">Model : </span>
                <span>{record["Model"]}</span>
              </div>
            </Col>
            <Col lg={4} md={6}>
              <div className="my-1">
                <span className="fw-bold">Sec17 : </span>
                <span>{record["Sec17"]}</span>
              </div>
            </Col>
            <Col lg={4} md={6}>
              <div className="my-1">
                <span className="fw-bold">Seasoning : </span>
                <span>{record["Seasoning"]}</span>
              </div>
            </Col>
            <Col lg={4} md={6}>
              <div className="my-1">
                <span className="fw-bold">TBR : </span>
                <span>{record["TBR"]}</span>
              </div>
            </Col>
            <Col lg={4} md={6}>
              <div className="my-1">
                <span className="fw-bold">Is Live : </span>
                {record.isLive ? (
                  <span className=" badge bg-success">Live</span>
                ) : (
                  <span className="badge bg-danger btn-rounded">Pending</span>
                )}
              </div>
            </Col>
            <Col lg={4} md={6}>
              <div className="my-1">
                <span className="fw-bold">Status : </span>
                {record.status === 1 ? (
                  <span className=" badge bg-success mx-3">Active</span>
                ) : (
                  <span className="badge bg-danger btn-rounded mx-3">
                    Inactive
                  </span>
                )}
              </div>
            </Col>
            <Col lg={4} md={6}>
              <div className="my-1">
                <span className="fw-bold">Stage : </span>
                {record.stage == "pending" ? (
                  <span className="badge" style={{ background: "#2e82f3" }}>
                    {record.stage}
                  </span>
                ) : record.stage == "close" ? (
                  <span className="badge" style={{ background: "#FF0000" }}>
                    {record.stage}
                  </span>
                ) : (
                  <span className="badge" style={{ background: "#FFA500" }}>
                    {record.stage}
                  </span>
                )}
              </div>
            </Col>
          </Row>

          <div className="my-2">
            <TableView
              products={products}
              columns={columns}
              filterData={{ filter, handelFilter }}
              Pagination={serverPagination}
            />
          </div>
        </ModalBody>
      </Modal>
    </div>
  );
};
export default ChangeStatusHisModal;
