import { get, post, put, del } from "../../helpers/api_helper";

//start user and user-group api
export const getUgListAction = (id = "", query = "") => {
  if (id) {
    return get(`/user-groups/${id}`);
  } else {
    return get(`/user-groups?data=${query}`);
  }
};

export const saveUserGroupAction = (data, id = "") => {
  if (id) {
    return post(`/user-groups/${id}`, data);
  } else {
    return post(`/user-groups`, data);
  }
};

export const delUserGroupAction = async (data, id) => {
  return del(`/user-groups/${id}`, { data });
};

export const getUserAction = (id = "", query = "") => {
  if (id) {
    return get(`/user/${id}`);
  } else {
    return get(`/user?data=${query}`);
  }
};

export const saveUserAction = async (data, id = "") => {
  if (id) {
    return post(`/user/${id}`, data);
  } else {
    return post(`/user`, data);
  }
};

export const resetPassAction = async (data, id) => {
  return post(`/user/resetPassword/${id}`, data);
};

export const getFormPermissions = async () => {
  return get(`/formPermission`);
};

export const savePermission = async data => {
  return post(`/formPermission`, data);
};

export const getFormAction = async () => {
  return get(`/formData`);
};

export const changePassAction = async data => {
  return post(`/user/changePassword`, data);
};

//end user and user-group api

//start partners api

export const getCountryListAction = async (id = "", query = "") => {
  if (id) {
    return get(`/country/${id}`);
  } else {
    return get(`/country?data=${query}`);
  }
};
export const getStateListAction = async (id = "", query = "") => {
  if (id) {
    return get(`/state/${id}`);
  } else {
    return get(`/state?data=${query}`);
  }
};
export const getCityListAction = async (id = "", query = "") => {
  if (id) {
    return get(`/city/${id}`);
  } else {
    return get(`/city?data=${query}`);
  }
};

export const getMasterTypeListAction = async (id = "", code = "") => {
  if (id) {
    return get(`/master/${id}`);
  } else {
    return get(`/master/${code}`);
  }
};

export const saveCountryAction = async (data, id = "") => {
  if (id) {
    return post(`/country/${id}`, data);
  } else {
    return post(`/country`, data);
  }
};

export const saveStateAction = async (data, id = "") => {
  if (id) {
    return post(`/state/${id}`, data);
  } else {
    return post(`/state`, data);
  }
};

export const saveCityAction = async (data, id = "") => {
  if (id) {
    return post(`/city/${id}`, data);
  } else {
    return post(`/city`, data);
  }
};

export const savePartnerAction = async (data, id = "") => {
  if (id) {
    return post(`/partner/${id}`, data);
  } else {
    return post(`/partner`, data);
  }
};

export const getPartnerAction = (id = "", query = "") => {
  if (id) {
    return get(`/partner/${id}`);
  } else {
    return get(`/partner?data=${query}`);
  }
};

export const getUploadAction = async (id = "", query) => {
  if (id) {
    return get(`/upload/${id}?data=${query}`);
  } else {
    return get(`/upload?data=${query}`);
  }
};

export const saveUploadAction = async (data, id = "") => {
  if (id) {
    return post(`/upload/${id}`, data);
  } else {
    return post(`/upload`, data);
  }
};

export const getUploadDataListAction = async query => {
  return get(`/uploadData?data=${query}`);
};

export const delUploadDataListAction = async (data, id = "") => {
  if (id) {
    return del(`/uploadData/${id}`, { data });
  }
};

export const deleteUploadAction = async (data, id = "") => {
  if (id) {
    return del(`/upload/${id}`, { data });
  }
};

export const uploadDataLiveAction = async (data, id) => {
  if (id) {
    return post(`/uploadData/${id}`, data);
  }
};

//update profile

export const getProfileAction = async () => {
  return get(`/user/profile`);
};
export const uploadProfileImgAction = async data => {
  return post(`/user/updateProfileImg`, data);
};

export const uploadProfileAction = async data => {
  return post(`/user/updateProfile`, data);
};

export const getSummaryAction = async () => {
  return get(`/dashboard/summary`);
};

export const getChangeStatusListAction = (id, query) => {
  if (id) {
    return get(`/changeStatus/data/${id}?data=${query}`);
  } else {
    return get(`/changeStatus/data?data=${query}`);
  }
};

export const changeStatusAction = async data => {
  return post("/change-status", data);
};

export const changeStatusHisAction = async (id, query) => {
  return get(`/change-status/history/${id}?data=${query}`);
};

export const forgetPwd = async data => {
  return post(`forget-password`, data);
};

export const savePartnerRegAction = async data => {
  return post(`/signup`, data);
};

export const saveQueryReqAction = async data => {
  return post(`/query`, data);
};
export const logoutAction = () => {
  return post(`/logout`);
};

export const notificationDetailsAction = () => {
  return get(`/notification`);
};

export const notificationReadAction = async id => {
  return put(`/notification/read`, id);
};
