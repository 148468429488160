// src/firebase.js
import { initializeApp } from "firebase/app";
import { getMessaging, getToken, onMessage } from "firebase/messaging";

const firebaseConfig = {
  apiKey: "AIzaSyAafuZ67xTxql6ek45MAYpZQeM_Jyjc9kk",
  authDomain: "recoveryfy-19c27.firebaseapp.com",
  projectId: "recoveryfy-19c27",
  storageBucket: "recoveryfy-19c27.appspot.com",
  messagingSenderId: "6693935433",
  appId: "1:6693935433:web:0f6d005f11daa01f566298",
  measurementId: "G-9D04RT7RGM",
};

const app = initializeApp(firebaseConfig);
const messaging = getMessaging(app);

export const requestPermission = async appType => {
  try {
    const permission = await Notification.requestPermission();
    if (permission === "granted") {
      const currentToken = await getToken(messaging, {
        vapidKey: `BJrlK59kTbNfQ136LFgKtYCNp4qu5kQc5fcnm-m3d80Cwp-Ocwv7By2PWZGp3Hpwztb3vzzDUePWWwPyyKVXBtc`,
      });
      if (currentToken) {
        return currentToken;
      } else {
        return false;
      }
    } else {
      return false;
    }
  } catch (err) {
    return false;
  }
};

export const onMessageListener = () =>
  new Promise(resolve => {
    onMessage(messaging, payload => {
      resolve(payload);
    });
  });
