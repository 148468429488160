import React, { useState } from "react";
import contact from "../../assets/images/homepage/Contact.png";
import { Field, Form, Formik } from "formik";
import * as Yup from "yup";
import { toast } from "react-toastify";
import Loader from "components/Common/Loader";
import { RotatingLines } from "react-loader-spinner";
import { saveQueryReqAction } from "store/actions";

const ContactUs = props => {
  const [loading, setLoading] = useState(false);
  const handleSubmit = async (values, actions) => {
    setLoading(true);
    try {
      const response = await saveQueryReqAction(values);
      if (response.status === 1) {
        setLoading(false);
        actions.resetForm();
        toast.success(response.msg);
      } else {
        toast.error(response.msg);
        setLoading(false);
      }
    } catch (error) {
      console.log(error);
      setLoading(false);
      let err = error?.response?.data?.msg || "Something went wrong";
      toast.error(err);
    }
  };
  const validationSchema = Yup.object().shape({
    email: Yup.string()
      .email("Please provide a valid email address.")
      .required("Please enter your email address."),
    name: Yup.string().required("Please enter your name."),
    subject: Yup.string().required("Please enter a subject for your inquiry."),
    message: Yup.string()
      .min(25, "Your description must be at least 25 characters long.")
      .required("Please provide a description of your inquiry."),
  });
  return (
    <div id="contact" className="container-fluid contact px-0">
      <div className="position-relative row m-0">
        <div className="px-0 col-12 col-md-5">
          <img src={contact} alt="contactimg" />
        </div>
        <div className="form p-0 col-12 col-md-7">
          <div className="container my-5 px-md-0">
            <div className="row text-white d-flex flex-column justify-content-center align-items-center mx-md-0">
              <div className="col-12 px-md-0">
                <div className="h4 text-white">Contact Us</div>
              </div>
              <p className="col-12 px-md-0">
                To learn more about our loan recovery services or discuss your
                specific requirements, please reach out to us. You can contact
                us via phone, email, or by filling out the contact form below to
                connect with our team.
              </p>
              <div className="col-12 px-md-0 mt-3">
                <Formik
                  enableReinitialize={true}
                  initialValues={{
                    name: "",
                    email: "",
                    subject: "",
                    message: "",
                  }}
                  validationSchema={validationSchema}
                  onSubmit={handleSubmit}
                >
                  {({ errors, status, touched, values }) => (
                    <Form className="row">
                      <div className="form-group col-12 col-md-6 my-2 my-md-1 my-lg-2">
                        <label className="mb-1" htmlFor="name">
                          Your Name
                        </label>
                        <Field
                          type="text"
                          name="name"
                          className={
                            "form-control" +
                            (errors.name && touched.name ? " is-invalid" : "")
                          }
                          id="name"
                          aria-describedby="nameHelp"
                          placeholder="Your Name"
                          value={values.name}
                        />
                        {errors.name && touched.name && (
                          <div className="invalid-feedback">{errors.name}</div>
                        )}
                      </div>

                      <div className="form-group col-12 col-md-6 my-2 my-md-1  my-lg-2">
                        <label className="mb-1" htmlFor="email">
                          Your Email
                        </label>
                        <Field
                          type="email"
                          name="email"
                          className={
                            "form-control" +
                            (errors.email && touched.email ? " is-invalid" : "")
                          }
                          id="email"
                          placeholder="Your Email"
                          value={values.email}
                        />
                        {errors.email && touched.email && (
                          <div className="invalid-feedback">{errors.email}</div>
                        )}
                      </div>
                      <div className="form-group col-12 my-2 my-md-1 my-lg-2">
                        <label className="mb-1" htmlFor="subject">
                          Subject
                        </label>
                        <Field
                          type="text"
                          name="subject"
                          className={
                            "form-control" +
                            (errors.subject && touched.subject
                              ? " is-invalid"
                              : "")
                          }
                          id="subject"
                          placeholder="Subject"
                          value={values.subject}
                        />
                        {errors.subject && touched.subject && (
                          <div className="invalid-feedback">
                            {errors.subject}
                          </div>
                        )}
                      </div>
                      <div className="form-group col-12 ">
                        <label className="mb-1" htmlFor="message">
                          Message
                        </label>
                        <Field
                          as="textarea"
                          name="message"
                          className={
                            "form-control" +
                            (errors.message && touched.message
                              ? " is-invalid"
                              : "")
                          }
                          id="message"
                          placeholder="Type your message here..."
                          rows={5}
                          aria-label="With textarea"
                          value={values.message}
                        />
                        {errors.message && touched.message && (
                          <div className="invalid-feedback">
                            {errors.message}
                          </div>
                        )}
                      </div>

                      <div className="mt-3 d-flex">
                        <button
                          className={`btn ms-auto ${loading ? "disabled" : ""}`}
                          type="submit"
                        >
                          {loading ? (
                            <RotatingLines
                              strokeColor="white"
                              strokeWidth="5"
                              animationDuration="0.75"
                              width="35"
                              visible={true}
                            />
                          ) : (
                            "Send Message"
                          )}
                        </button>
                      </div>
                    </Form>
                  )}
                </Formik>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Loader(ContactUs);
