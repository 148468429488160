import React, { useEffect, useState } from "react";

import { Row, Col, Card, CardBody, CardTitle } from "reactstrap";
import ApexRadial from "./ApexRadial";

const MonthlyEarning = props => {
  const [record, setRecord] = useState(props.data);
  useEffect(() => {
    if (props.data) {
      setRecord(props.data);
    }
  }, [props]);

  return (
    <React.Fragment>
      {" "}
      <Card>
        <CardBody>
          <CardTitle className="mb-4 h4">Last 30 days status</CardTitle>
          <Row>
            <Col sm="6">
              <p className="text-muted">Live</p>
              <h3>{record.last30DaysLive}</h3>
              <p className="text-muted">
                <span
                  className={
                    record.liveProgress >= 0
                      ? "text-success me-2"
                      : "text-danger me-2"
                  }
                >
                  {record.liveProgress}{" "}
                  <i
                    className={
                      record.liveProgress >= 0
                        ? "mdi mdi-arrow-up"
                        : "mdi mdi-arrow-up"
                    }
                  ></i>
                </span>
                From previous period
              </p>
            </Col>
            <Col sm="6">
              <div className="mt-4 mt-sm-0">
                <ApexRadial value={record.last30DaysClose} />
              </div>
            </Col>
          </Row>
        </CardBody>
      </Card>
    </React.Fragment>
  );
};

export default MonthlyEarning;
