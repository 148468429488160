import React, { useEffect, useState } from "react";

import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Container,
  Row,
  Col,
  Alert,
  Label,
} from "reactstrap";
import CustomCloseButton from "./CustomCloseButton";
import PropTypes from "prop-types";
// Redux
import { connect, useSelector } from "react-redux";
import { Link, withRouter } from "react-router-dom";

import { Formik, Field, Form, ErrorMessage } from "formik";
import * as Yup from "yup";

// actions
import { apiError, loginUser } from "../../store/actions";
import { RotatingLines } from "react-loader-spinner";
import usePasswordToggle from "hooks/usePasswordToggle";
import { requestPermission } from "components/Common/firebase";

function LoginModal(props) {
  const logo =
    "https://recovery.newitzone.com/api/public/assets/images/Logo2.png";
  const [modal, setModal] = useState(false);
  const [passIcon, passInputType] = usePasswordToggle();
  const [fcmToken, setFcmToken] = useState("");

  const toggle = () => {
    setModal(!modal);
    props.isToggle();
  };
  const loginState = useSelector(state => state.Login);
  const formData = { userName: "", pass: "" };
  const [systemInfo, setSystemInfo] = useState({
    latitude: "",
    longitude: "",
    timestamp: "",
    error: 0,
  });
  const successCallback = position => {
    setSystemInfo({
      ...setSystemInfo,
      latitude: position.coords.latitude,
      longitude: position.coords.longitude,
      timestamp: position.timestamp,
    });
  };
  const errorCallback = error => {
    setSystemInfo({ ...systemInfo, error: 1 });
  };
  useEffect(() => {
    navigator.geolocation.getCurrentPosition(successCallback, errorCallback);
  }, []);
  useEffect(async () => {
    setModal(props.isOpen);
    const generateFCM = await requestPermission();
    if (generateFCM) {
      setFcmToken(generateFCM);
    }
  }, [props.isOpen]);

  const handleSubmit = values => {
    // if (systemInfo.error) {
    //   toast.error("Please trun on location");
    //   return;
    // }
    values = {
      ...values,
      systemInfo: navigator.userAgentData,
      latitude: systemInfo.latitude,
      longitude: systemInfo.longitude,
      fcmToken: fcmToken,
      appType: "web",
    };
    props.loginUser(values, props.history);
  };

  return (
    <div>
      <Modal
        isOpen={modal}
        toggle={toggle}
        centered
        backdrop={false}
        className="homepage-modal"
        size="custom"
      >
        <ModalHeader
          toggle={toggle}
          close={<CustomCloseButton onClick={toggle} />}
          className="header"
        >
          <img src={logo} alt="logo" className="brand-logo" />
        </ModalHeader>
        <ModalBody className="pb-5">
          <Container>
            <Row className="justify-content-center">
              <Col>
                <Row>
                  <Col>
                    <div className="text-primary p-4">
                      <h5 className="h2">Login</h5>
                    </div>
                  </Col>
                </Row>

                <div className="p-2">
                  {props.error && typeof props.error === "string" ? (
                    <Alert color="danger">{props.error}</Alert>
                  ) : null}
                  <Formik
                    enableReinitialize={true}
                    initialValues={formData}
                    validationSchema={Yup.object().shape({
                      userName: Yup.string()
                        .email("Please provide a valid email address.")
                        .required("Please enter your email address."),
                      pass: Yup.string().required(
                        "Please enter your password."
                      ),
                    })}
                    onSubmit={handleSubmit}
                  >
                    {({ errors, status, touched, values }) => (
                      <Form className="form-horizontal">
                        <div className="mb-3">
                          <Label for="userName" className="form-label">
                            Enter Email Address
                          </Label>
                          <Field
                            name="userName"
                            type="text"
                            className={
                              "form-control" +
                              (errors.userName && touched.userName
                                ? " is-invalid"
                                : "")
                            }
                          />
                          <ErrorMessage
                            name="userName"
                            component="div"
                            className="invalid-feedback"
                          />
                        </div>
                        <div className="mb-3 position-relative">
                          <Label for="pass" className="form-label">
                            Password
                          </Label>
                          <div className="input-group auth-pass-inputgroup">
                            <Field
                              name="pass"
                              type={passInputType}
                              autoComplete="true"
                              className={
                                "form-control" +
                                (errors.pass && touched.pass
                                  ? " is-invalid"
                                  : "")
                              }
                            />
                            {values.pass && (
                              <span className="password-toggle-icon">
                                {passIcon}
                              </span>
                            )}

                            {errors.pass && (
                              <span className="invalid-feedback mt-1">
                                {errors.pass}
                              </span>
                            )}
                          </div>
                          {/* <ErrorMessage
                            name="pass"
                            component="div"
                            className="invalid-feedback"
                          /> */}
                        </div>

                        <div className="d-flex my-4">
                          <Field
                            type="checkbox"
                            name="remember"
                            id="remember"
                            label="Check the mark"
                          />

                          <Label
                            className="form-check-label ms-2 fw-bold"
                            htmlFor="remember"
                          >
                            Remember me
                          </Label>
                        </div>
                        <div className="mt-3 d-grid">
                          {loginState.loading ? (
                            <button
                              className="btn btn-danger btn-block"
                              type="button"
                            >
                              <RotatingLines
                                strokeColor="white"
                                strokeWidth="5"
                                animationDuration="0.75"
                                width="30"
                                visible={true}
                              />
                            </button>
                          ) : (
                            <button
                              className="btn btn-danger btn-block"
                              type="submit"
                            >
                              Log In
                            </button>
                          )}
                        </div>
                        <div
                          className="mt-3 d-grid"
                          onClick={() => {
                            toggle();
                            props.otherModal.signUpToggle();
                          }}
                        >
                          <button
                            className="btn btn-block btn-outline-danger"
                            type="button"
                          >
                            Sign Up
                          </button>
                        </div>
                        <div
                          className="mt-3 d-grid"
                          onClick={() => {
                            toggle();
                            props.otherModal.forgetToggle();
                          }}
                        >
                          <button
                            className="btn btn-block btn-outline-danger"
                            type="button"
                          >
                            Forgot Password
                          </button>
                        </div>
                      </Form>
                    )}
                  </Formik>
                </div>
              </Col>
            </Row>
          </Container>
        </ModalBody>
      </Modal>
    </div>
  );
}

LoginModal.propTypes = {
  apiError: PropTypes.any,
  error: PropTypes.any,
  history: PropTypes.object,
  loginUser: PropTypes.func,
  socialLogin: PropTypes.func,
};

const mapStateToProps = state => {
  const { error } = state.Login;
  return { error };
};

export default withRouter(
  connect(mapStateToProps, { loginUser, apiError })(LoginModal)
);
