import Breadcrumbs from "components/Common/Breadcrumb";
import Loader from "components/Common/Loader";
import { getFormId } from "helpers/common_helper";
import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import {
  Accordion,
  AccordionBody,
  AccordionHeader,
  AccordionItem,
  Button,
  Card,
  CardBody,
  Col,
  Container,
  Row,
} from "reactstrap";
import {
  getFormAction,
  getFormPermissions,
  getUgListAction,
  savePermission,
} from "store/actions";
const Permissions = props => {
  const [open, setOpen] = useState("");
  const toggle = id => {
    if (open !== id) {
      setOpen(id);
    }
  };
  const [selectForms, setSelectForms] = useState({});
  const [userGroups, setUserGroups] = useState([]);
  const [formDataList, setFormDataList] = useState({
    parent: [],
    children1: [],
    children2: [],
  });
  const [filteredFormDataList, setFilteredFormDataList] = useState({
    parent: [],
    children1: [],
    children2: [],
  });
  const [selectUg, setSelectUg] = useState("");
  useEffect(async () => {
    props.setLoading(true);
    try {
      const response = await getUgListAction();
      if (response.status === 1) {
        setUserGroups(response.data);
        props.setLoading(false);
      } else {
        props.setLoading(false);
        toast.error(response.msg);
      }
    } catch (error) {
      props.setLoading(false);
      let err = error?.response?.data?.msg || "Something went wrong";
      toast.error(err);
    }
  }, []);
  useEffect(async () => {
    try {
      props.setLoading(true);
      const response = await getFormAction();
      if (response.status === 1) {
        let parent = response.data
          .filter(val => val.label === 0)
          .sort((a, b) => a.name.localeCompare(b.name));
        let children1 = response.data
          .filter(val => val.label === 1)
          .sort((a, b) => a.name.localeCompare(b.name));
        let children2 = response.data
          .filter(val => val.label === 2)
          .sort((a, b) => a.name.localeCompare(b.name));
        setFormDataList({
          ...formDataList,
          parent: parent,
          children1: children1,
          children2: children2,
        });

        props.setLoading(false);
      } else {
        props.setLoading(false);
        toast.error(response.msg);
      }
    } catch (error) {
      props.setLoading(false);
      let err = error?.response?.data?.msg || "Something went wrong";
      toast.error(err);
    }
  }, []);

  const filterForm = ugId => {
    let userGroup = userGroups.filter(val => val.id == ugId)[0];
    const fParent = formDataList.parent.filter(val => {
      return val.userTypes.includes(userGroup.userType);
    });
    const fChildren1 = formDataList.children1.filter(val => {
      return val.userTypes.includes(userGroup.userType);
    });
    const fChildren2 = formDataList.children2.filter(val => {
      return val.userTypes.includes(userGroup.userType);
    });
    setFilteredFormDataList({
      parent: fParent,
      children1: fChildren1,
      children2: fChildren2,
    });
  };
  useEffect(async () => {
    try {
      if (selectUg) {
        props.setLoading(true);
        filterForm(selectUg);
        const response = await getFormPermissions();
        if (response.status === 1) {
          let data = {};
          response.data
            .filter(val => val.userGroupId === selectUg)
            .map(val => {
              data[val.formId] = { ...val };
              if (
                data[val.formId].allowCreate === 1 &&
                data[val.formId].allowEdit === 1 &&
                data[val.formId].allowView === 1 &&
                data[val.formId].allowDelete === 1
              ) {
                data[val.formId].all = 1;
              } else {
                data[val.formId].all = 0;
              }
            });
          setSelectForms(data);
          props.setLoading(false);
        } else {
          props.setLoading(false);
          toast.error(response.msg);
        }
      } else {
      }
    } catch (error) {
      props.setLoading(false);
      let err = error?.response?.data?.msg || "Something went wrong";
      toast.error(err);
    }
  }, [selectUg]);

  const onCheckboxChange = (key, e) => {
    const { name } = e.target;
    let chk = false;
    if (e.target.checked) {
      chk = true;
    }
    let sfi = JSON.parse(JSON.stringify(selectForms));
    sfi[key] = sfi[key]
      ? sfi[key]
      : { allowCreate: 0, allowView: 0, allowEdit: 0, allowDelete: 0, all: 0 };
    if (name === "all") {
      sfi[key].all = chk ? 1 : 0;
      sfi[key].allowCreate = chk ? 1 : 0;
      sfi[key].allowView = chk ? 1 : 0;
      sfi[key].allowEdit = chk ? 1 : 0;
      sfi[key].allowDelete = chk ? 1 : 0;
    } else {
      sfi[key][name] = chk ? 1 : 0;
      if (
        sfi[key].allowCreate === 1 &&
        sfi[key].allowEdit === 1 &&
        sfi[key].allowView === 1 &&
        sfi[key].allowDelete === 1
      ) {
        sfi[key].all = 1;
      } else {
        sfi[key].all = 0;
      }
    }
    setSelectForms(sfi);
  };

  const handleSubmit = async () => {
    try {
      props.setLoading(true);
      let sfi = JSON.parse(JSON.stringify(selectForms));
      if (Object.keys(selectForms).length === 0) {
        toast.error("Tick the form permission");
        props.setLoading(false);
        return;
      }
      for (const key in sfi) {
        if (
          sfi[key].allowCreate === 0 &&
          sfi[key].allowEdit === 0 &&
          sfi[key].allowView === 0 &&
          sfi[key].allowDelete === 0
        ) {
          delete sfi[key];
        }
      }
      let response = await savePermission({
        permissions: sfi,
        userGroupId: selectUg,
        formId: getFormId(),
      });
      if (response.status === 1) {
        props.setLoading(false);
        toast.success(response.msg);
      } else {
        props.setLoading(false);
        toast.error(response.msg);
      }
    } catch (error) {
      props.setLoading(false);
      let err = error?.response?.data?.msg || "Something went wrong";
      toast.error(err);
    }
  };
  return (
    <div className="page-content">
      <Container>
        {/* <Breadcrumbs title="User group" breadcrumbItem="permission" /> */}

        <Row>
          <Col className="col-12">
            <Card>
              <CardBody>
                <Row>
                  <div className="col-12">
                    <div className="form-group">
                      <label htmlFor="selectUg">
                        User group select
                        <span htmlFor="" className=" text-danger">
                          &nbsp; *
                        </span>
                      </label>
                      <select
                        className="form-select"
                        id="selectUg"
                        name="selectUg"
                        onChange={e => setSelectUg(e.target.value)}
                      >
                        <option value="">Select user group</option>
                        {userGroups.map(val => (
                          <option value={val.id} key={val.id}>
                            {val.name}
                          </option>
                        ))}
                      </select>
                    </div>
                  </div>
                </Row>

                {selectUg && (
                  <Row className="my-4">
                    <div className="col-12">
                      <Accordion open={open} toggle={toggle}>
                        {filteredFormDataList.children1.map((val, i) => (
                          <AccordionItem key={val.id} className="">
                            <AccordionHeader targetId={`${i + 1}`}>
                              {`${val.name}`}&nbsp;
                              <small>
                                {` (${
                                  filteredFormDataList.parent.filter(
                                    v => val.underId === v.id
                                  )[0].name
                                })`}
                              </small>
                            </AccordionHeader>
                            <AccordionBody accordionId={`${i + 1}`}>
                              <table className="table">
                                <tbody>
                                  {filteredFormDataList.children2
                                    .filter(val2 => val.id === val2.underId)
                                    .map(val3 => (
                                      <tr key={val3.id}>
                                        <th scope="row">{val3.name}</th>
                                        <td className=" table-hover">
                                          <div className="d-flex align-items-center">
                                            <input
                                              type="checkbox"
                                              name="allowView"
                                              id={`${val3.id}-view`}
                                              onChange={e => {
                                                onCheckboxChange(val3.id, e);
                                              }}
                                              checked={
                                                selectForms[val3.id]
                                                  ?.allowView === 1
                                                  ? true
                                                  : false || false
                                              }
                                            />
                                            <label
                                              htmlFor={`${val3.id}-view`}
                                              className="ms-1 m-0"
                                            >
                                              View
                                            </label>
                                          </div>
                                        </td>
                                        <td>
                                          <div className="d-flex align-items-center">
                                            <input
                                              type="checkbox"
                                              name="allowEdit"
                                              id={`${val3.id}-edit`}
                                              onChange={e => {
                                                onCheckboxChange(val3.id, e);
                                              }}
                                              checked={
                                                selectForms[val3.id]
                                                  ?.allowEdit === 1
                                                  ? true
                                                  : false || false
                                              }
                                            />
                                            <label
                                              htmlFor={`${val3.id}-edit`}
                                              className="ms-1 m-0"
                                            >
                                              Edit
                                            </label>
                                          </div>
                                        </td>
                                        <td>
                                          <div className="d-flex align-items-center">
                                            <input
                                              type="checkbox"
                                              name="allowCreate"
                                              id={`${val3.id}-create`}
                                              onChange={e => {
                                                onCheckboxChange(val3.id, e);
                                              }}
                                              checked={
                                                selectForms[val3.id]
                                                  ?.allowCreate === 1
                                                  ? true
                                                  : false || false
                                              }
                                            />
                                            <label
                                              htmlFor={`${val3.id}-create`}
                                              className="ms-1 m-0"
                                            >
                                              Create
                                            </label>
                                          </div>
                                        </td>
                                        <td>
                                          <div className="d-flex align-items-center">
                                            <input
                                              type="checkbox"
                                              name="allowDelete"
                                              id={`${val3.id}-delete`}
                                              onChange={e => {
                                                onCheckboxChange(val3.id, e);
                                              }}
                                              checked={
                                                selectForms[val3.id]
                                                  ?.allowDelete === 1
                                                  ? true
                                                  : false || false
                                              }
                                            />
                                            <label
                                              htmlFor={`${val3.id}-delete`}
                                              className="ms-1 m-0"
                                            >
                                              Delete
                                            </label>
                                          </div>
                                        </td>
                                        <td>
                                          <div className="d-flex align-items-center">
                                            <input
                                              type="checkbox"
                                              name="all"
                                              id={`${val3.id}-all`}
                                              onChange={e => {
                                                onCheckboxChange(val3.id, e);
                                              }}
                                              checked={
                                                selectForms[val3.id]?.all === 1
                                                  ? true
                                                  : false || false
                                              }
                                            />
                                            <label
                                              htmlFor={`${val3.id}-all`}
                                              className="ms-1 m-0"
                                            >
                                              All
                                            </label>
                                          </div>
                                        </td>
                                      </tr>
                                    ))}
                                </tbody>
                              </table>
                            </AccordionBody>
                          </AccordionItem>
                        ))}
                      </Accordion>
                    </div>
                  </Row>
                )}

                <Row>
                  <Col xs={{ size: 2, offset: 5 }}>
                    {selectUg && (
                      <Button color="primary" onClick={handleSubmit}>
                        Save
                      </Button>
                    )}
                  </Col>
                </Row>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default Loader(Permissions);
