import ConfirmModel from "components/Common/ConfirmModel";
import TableView from "components/Common/TableView";
import React, { useCallback, useEffect, useState } from "react";
import { MetaTags } from "react-meta-tags";
import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import {
  delUploadDataListAction,
  getMasterTypeListAction,
  getUploadDataListAction,
} from "store/actions";
import { selectFilter, textFilter } from "react-bootstrap-table2-filter";
import useFilter from "components/Common/useFilter";
import { encrypt } from "helpers/common_helper";

const UploadList = props => {
  const [products, setProducts] = useState([]);
  const [deletedRowId, setDeletedRowId] = useState("");
  const [confirmModelPre, setConfModelPro] = useState({
    modelMessage: "",
    confirmationState: false,
    modelOpen: false,
  });
  const [filter, handelFilter] = useFilter(6);
  const [categoryOptions, setCategoryOptions] = useState([]);
  const [serverPagination, setServerPagination] = useState({
    totalItems: "",
    currentPage: "",
    itemsPerPage: "",
    totalPages: "",
    hasNextPage: "",
  });
  const [category, setCategory] = useState([]);
  const { formId, auth } = props;
  const history = useHistory();
  useEffect(async () => {
    props.setLoading(true);
    try {
      const encodedQueryString = encrypt({
        ...filter,
        sortField: "createdAt",
        sortDirection: "DESC",
        sortOrder: "DESC",
      });
      const response = await getUploadDataListAction(encodedQueryString);

      if (response.status === 1) {
        const {
          totalItems,
          currentPage,
          itemsPerPage,
          totalPages,
          hasNextPage,
        } = response.metadata;
        setServerPagination(prev => ({
          ...prev,
          totalItems,
          currentPage,
          itemsPerPage,
          totalPages,
          hasNextPage,
        }));
        setProducts(response.data);
        props.setLoading(false);
      } else {
        props.setLoading(false);
        toast.error(response.msg);
      }
    } catch (error) {
      props.setLoading(false);
      console.log(error);
      let err = error?.response?.data?.msg || "Something went wrong";
      toast.error(err);
    }
  }, [filter]);

  useEffect(async () => {
    try {
      let response1 = await getMasterTypeListAction("", "1004");
      if (response1.status === 1) {
        props.setLoading(false);
        let cateOptions;
        let arr = response1?.data || [];
        cateOptions = arr.map(val => {
          return { value: val.name, label: val.name };
        });
        cateOptions.unshift({ value: "", label: "All" });
        setCategoryOptions(cateOptions);
        setCategory(arr);
      } else {
        toast.error(response1.msg);
        props.setLoading(false);
      }
    } catch (error) {
      props.setLoading(false);
      let err = error?.response?.data?.msg || "Something went wrong";
      toast.error(err);
    }
  }, []);

  const filterFields = [
    { dataField: "userName", text: "User Name" },
    {
      dataField: "companyName",
      text: "Com. Name",
      placeholder: "Company Name",
    },
    {
      dataField: "name",
      text: "Category",
      filter: categoryOptions,
      defaultValue: "",
    },
  ];
  const columns = [
    {
      dataField: "id",
      text: "Id",
      sort: true,
      formatter: (cell, row, rowIndex) => {
        return rowIndex + 1;
      },
    },
    {
      dataField: "filename",
      text: "File",
      sort: true,
      formatter: (cell, row) => {
        return row.localFilename ? row.localFilename : row.filename;
      },
    },
    {
      dataField: "userName",
      text: "User name",
    },
    {
      dataField: "companyName",
      text: "Company name",
    },
    {
      dataField: "name",
      text: "Category",
    },
    {
      dataField: "isLive",
      text: "Is Live",
      formatter: data => {
        return data === 1 ? (
          <span className=" badge bg-success">Live</span>
        ) : (
          <span className="badge bg-danger btn-rounded">Pending</span>
        );
      },
    },
    {
      dataField: "createdAt",
      sort: true,
      text: "createdAt",
      formatter: data => {
        return new Date(data).toLocaleString("en-GB");
      },
    },
    {
      dataField: "liveDate",
      text: "Live Date",
      sort: true,
      formatter: (data, row) => {
        return row.isLive ? new Date(data).toLocaleString("en-GB") : "";
      },
    },
    {
      dataField: "status",
      text: "Status",
      sort: true,
      formatter: data => {
        return data === 1 ? (
          <span className=" badge bg-success">Active</span>
        ) : (
          <span className="badge bg-danger btn-rounded">Inactive</span>
        );
      },
    },
    {
      dataField: "isLiveStatus",
      text: "Upload Status",
      formatter: data => {
        return data == 1 ? (
          <span className=" badge bg-success">Uploaded</span>
        ) : data == 0 ? (
          <span className="badge bg-primary btn-rounded">Uploading..</span>
        ) : (
          <span className="badge bg-danger btn-rounded">Error</span>
        );
      },
    },
  ];

  const tableButton = {
    text: "Upload Data",
    path: "upload/data",
  };
  const handleView = async row => {
    history.push(`/panel/upload/history/${row.id}`, { row });
  };

  const handleDelete = async row => {
    setDeletedRowId(row.id);
    setConfModelPro({
      ...confirmModelPre,
      modelMessage: `Are you sure you want to delete ${row.filename} file data`,
      modelOpen: true,
    });
  };
  const handleConfState = async stateValue => {
    try {
      setConfModelPro({
        ...confirmModelPre,
        confirmationState: stateValue,
        modelOpen: false,
      });
      props.setLoading(true);
      if (stateValue) {
        const response = await delUploadDataListAction(
          {
            formId: formId,
          },
          deletedRowId
        );
        if (response.status === 1) {
          props.setLoading(false);
          const data = products.filter(val => val.id !== deletedRowId);
          setProducts(data);
          toast.success(response.msg);
          handelFilter({
            ...filter,
            pagination: {
              page: 1,
            },
          });
        } else {
          props.setLoading(false);
          toast.error(response.msg);
        }
      } else {
        props.setLoading(false);
      }
    } catch (error) {
      props.setLoading(false);
      let err = error?.response?.data?.msg || "Something went wrong";
      toast.error(err);
    }
  };
  const handleClose = modelState => {
    setConfModelPro({ ...confirmModelPre, modelOpen: modelState });
  };
  return (
    <div>
      {confirmModelPre.modelOpen && (
        <ConfirmModel
          confirmationStateAction={handleConfState}
          message={confirmModelPre.modelMessage}
          closeAction={handleClose}
        />
      )}
      <MetaTags>
        <title>Upload List | Recoveryfy</title>
      </MetaTags>
      <TableView
        tableName="All Records"
        columns={columns}
        products={products}
        filterData={{ filter, handelFilter }}
        Pagination={serverPagination}
        sortDate={{ form: filter.from, to: filter.to }}
        viewAction={auth.allowView ? handleView : ""}
        deleteRowAction={auth.allowDelete ? handleDelete : ""}
        buttonAdd={auth.allowCreate ? tableButton : ""}
        filterFields={filterFields}
      />
    </div>
  );
};

export default UploadList;
