const publicRoute = [
  "panel/dashboard",
  "panel/user/profile",
  "panel/user/changepassword",
];
export const getTokenDetails = () => {
  let localData = localStorage.getItem("authUser")
    ? JSON.parse(localStorage.getItem("authUser"))
    : {};
  const accessToken = localData.accessToken ? localData.accessToken : "";
  let tokenData = null;
  if (accessToken) {
    let base64Url = accessToken.split(".")[1];
    let base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
    let jsonPayload = decodeURIComponent(
      atob(base64)
        .split("")
        .map(function (c) {
          return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2);
        })
        .join("")
    );

    tokenData = JSON.parse(jsonPayload);
  }
  return tokenData;
};

export const parseJwt = token => {
  if (token) {
    var base64Url = token.split(".")[1];
    var base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
    var jsonPayload = decodeURIComponent(
      atob(base64)
        .split("")
        .map(function (c) {
          return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2);
        })
        .join("")
    );

    return JSON.parse(jsonPayload);
  } else {
    return false;
  }
};

export const getFormId = () => {
  let authUser = localStorage.getItem("authUser");
  authUser = authUser ? JSON.parse(authUser) : {};
  let formId = "";
  if (authUser) {
    let formUrls = authUser?.formVsUrl || [];
    if (formUrls.length > 0) {
      let winPathArr = window.location.pathname.split("/");
      winPathArr = winPathArr.filter(v => v != "");
      let path = winPathArr.length > 0 ? `/${winPathArr[0]}` : "";
      path = winPathArr.length > 1 ? `${path}/${winPathArr[1]}` : path;
      path = winPathArr.length > 2 ? `${path}/${winPathArr[2]}` : path;
      if (path !== "") {
        let fltUrl = formUrls.filter(v => v.url === path);
        formId = fltUrl.length > 0 ? fltUrl[0].fid : "";
      }
    }
  }
  return formId;
};

export const urlValidation = id => {
  let authUser = localStorage.getItem("authUser");
  authUser = authUser ? JSON.parse(authUser) : {};
  let winPathArr = window.location.pathname.split("/");
  winPathArr = winPathArr.filter(v => v != "").join("/");
  const publicRouteExist = publicRoute.includes(winPathArr);
  if (publicRouteExist) {
    return true;
  }
  let form = "";
  if (authUser) {
    let formUrls = authUser?.formVsUrl || [];
    if (id) {
      formUrls = formUrls.filter(val => val.fid == id);
      return formUrls[0];
    } else {
      return window.location.replace("/panel/dashboard");
    }
  }
};

export const encrypt = filter => {
  let filterParam = {};
  Object.entries(filter).forEach(([key, value]) => {
    filterParam[key] = value;
  });

  const queryString = JSON.stringify(filterParam);
  return btoa(queryString);
};

export const scrollToSection = (e, sectionId) => {
  e.preventDefault();
  const section = document.getElementById(sectionId);
  if (section) {
    const offset = 85; // Adjust this value to control the space at the top
    window.scrollTo({
      top: sectionId == "home" ? 0 : section.offsetTop - offset,
      behavior: "smooth",
    });
  }
};
