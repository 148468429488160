import React, { useEffect, useState } from "react";

import { Row, Col, Card, CardBody } from "reactstrap";
import { Link } from "react-router-dom";

import avatar1 from "../../assets/images/users/avatar-1.jpg";
import profileImg from "../../assets/images/profile-img.png";

const WelcomeComp = props => {
  const [values, setValues] = useState({});
  const [profile, setProfile] = useState(false);

  useEffect(async () => {
    let profile = localStorage.getItem("profile");
    profile = profile ? JSON.parse(profile) : {};
    if (profile) {
      setValues(profile);
      setProfile(profile.profileImg.includes("noimage.png"));
    }
  }, [localStorage.getItem("profile")]);
  return (
    <React.Fragment>
      <Card className="overflow-hidden">
        <div className="bg-primary bg-soft">
          <Row>
            <Col xs="7">
              <div className="text-primary p-3">
                <h5 className="text-primary">Welcome Back !</h5>
                <p>Recoveryfy Dashboard</p>
              </div>
            </Col>
            <Col xs="5" className="align-self-end">
              <img src={profileImg} alt="" className="img-fluid" />
            </Col>
          </Row>
        </div>
        <CardBody className="pt-0">
          <Row>
            <Col sm="4">
              <div className="avatar-md profile-user-wid mb-4">
                {profile ? (
                  <img
                    src={avatar1}
                    alt=""
                    className="img-thumbnail rounded-circle"
                  />
                ) : (
                  <img
                    src={values.profileImg}
                    alt=""
                    className="avatar-md rounded-circle img-thumbnail"
                  />
                )}
              </div>
              <h5 className="font-size-15 text-truncate">{values.fName}</h5>
              <p className="text-muted mb-0 text-truncate">{values.userType}</p>
            </Col>

            <Col sm="8">
              <div className="pt-4">
                <Row>
                  <Col xs="6">
                    <h5 className="font-size-15">User code</h5>
                    <p className="text-muted mb-0">{values.code}</p>
                  </Col>
                  <Col xs="6">
                    <h5 className="font-size-15">User group</h5>
                    <p className="text-muted mb-0">{values.groupName}</p>
                  </Col>
                </Row>
                <div className="mt-4">
                  <Link
                    to="/panel/user/profile"
                    className="btn btn-primary btn-sm"
                  >
                    View Profile <i className="mdi mdi-arrow-right ms-1" />
                  </Link>
                </div>
              </div>
            </Col>
          </Row>
        </CardBody>
      </Card>
    </React.Fragment>
  );
};

export default WelcomeComp;
