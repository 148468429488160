import { takeEvery, put, call, takeLatest } from "redux-saga/effects";

// Login Redux States
import { LOGIN_USER, LOGOUT_USER } from "./actionTypes";
import { loginSuccess, apiError } from "./actions";

//Include Both Helper File with needed methods
import { postLogin } from "../../../helpers/backend_helper";
function* loginUser({ payload: { user, history } }) {
  try {
    const response = yield call(postLogin, {
      userName: user.userName,
      password: user.pass,
      systemInfo: JSON.stringify(user.systemInfo),
      latitude: user.latitude,
      longitude: user.longitude,
      fcmToken: user.fcmToken,
      appType: user.appType,
    });
    if (response) {
      localStorage.setItem(
        "profile",
        JSON.stringify({ ...response.data.userInfo })
      );
      delete response.data.userInfo;
      localStorage.setItem("authUser", JSON.stringify(response.data));
      yield put(loginSuccess(response.data));
      history.push("/panel/dashboard");
    } else {
      yield put(apiError("Somethings went wrong."));
    }
  } catch (error) {
    yield put(apiError(error?.response?.data?.msg || "Invalid Credentials."));
  }
}

function* logoutUser({ payload: { history } }) {
  try {
    localStorage.removeItem("authUser");
    localStorage.removeItem("profile");
    history.push("/");
  } catch (error) {
    yield put(apiError(error));
  }
}

function* authSaga() {
  yield takeEvery(LOGIN_USER, loginUser);
  yield takeEvery(LOGOUT_USER, logoutUser);
}

export default authSaga;
