import React from "react";
import logo1 from "../../assets/images/homepage/feature1.png";
import logo2 from "../../assets/images/homepage/feature2.png";
import logo3 from "../../assets/images/homepage/feature3.png";
import logo4 from "../../assets/images/homepage/feature4.png";
import logo5 from "../../assets/images/homepage/feature5.png";
import logo6 from "../../assets/images/homepage/feature6.png";
import logo7 from "../../assets/images/homepage/feature7.png";
import logo8 from "../../assets/images/homepage/feature8.png";
import { Container, Row } from "reactstrap";

const Features = () => {
  return (
    <Container id="features" fluid className="px-xl-5 mb-5 features">
      <div className="d-flex flex-column align-items-center py-1">
        <h3 className="underline">Features</h3>
        <p className="features-text">
          Introducing our cutting-edge features designed to revolutionize the
          loan recovery process and maximize your chances of successful debt
          collection.
        </p>
        <div className="m-0 p-0 d-flex flex-column flex-md-row flex-wrap g-32">
          <div className="w-50">
            <div className="card">
              <div className="card-body d-flex flex-column flex-lg-row align-items-center">
                <div className="logo-container mb-lg-0">
                  <img src={logo1} alt="feature1" />
                </div>
                <div className="details">
                  <h6>Perfect Dashboard</h6>
                  <p>
                    Our user-friendly dashboard provides real-time updates on
                    recovery progress, allowing you to monitor and track the
                    status of each case effortlessly.
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="w-50">
            <div className="card">
              <div className="card-body d-flex align-items-center flex-column flex-lg-row">
                <div className="logo-container mb-lg-0">
                  <img src={logo2} alt="feature1" />
                </div>
                <div className="details">
                  <h6>Transparent Accounting System</h6>
                  <p>
                    Our accounting system ensures transparent financial records
                    in loan recovery, promoting accountability and accurate
                    tracking of recovered amounts.
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="w-50">
            <div className="card">
              <div className="card-body d-flex align-items-center flex-column flex-lg-row">
                <div className="logo-container mb-lg-0">
                  <img src={logo3} alt="feature1" />
                </div>
                <div className="details">
                  <h6>Mobile App with Offline Mode</h6>
                  <p>
                    Our offline-enabled mobile app empowers recovery agents with
                    uninterrupted access to critical information for enhanced
                    productivity.
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="w-50">
            <div className="card">
              <div className="card-body d-flex align-items-center flex-column flex-lg-row">
                <div className="logo-container mb-lg-0">
                  <img src={logo4} alt="feature1" />
                </div>
                <div className="details">
                  <h6>Data Analytics and Insights</h6>
                  <p>
                    We utilizes data analytics to optimize recovery strategies,
                    improve rates by analyzing debtor behavior, payment
                    patterns, and trends.
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="w-50">
            <div className="card">
              <div className="card-body d-flex align-items-center flex-column flex-lg-row">
                <div className="logo-container mb-lg-0">
                  <img src={logo5} alt="feature1" />
                </div>
                <div className="details">
                  <h6>Tracking and Monitoring</h6>
                  <p>
                    Recoveryfy enables real-time tracking and monitoring of
                    recovery agents, ensuring transparency and accountability in
                    the process.
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="w-50">
            <div className="card">
              <div className="card-body d-flex align-items-center flex-column flex-lg-row">
                <div className="logo-container mb-lg-0">
                  <img src={logo6} alt="feature1" />
                </div>
                <div className="details">
                  <h6>Online Payment Gateway</h6>
                  <p>
                    Our secure online payment gateway streamlines the payment
                    process, increasing chances of debt recovery and enhancing
                    user experience.
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="w-50">
            <div className="card">
              <div className="card-body d-flex align-items-center flex-column flex-lg-row">
                <div className="logo-container mb-lg-0">
                  <img src={logo7} alt="feature1" />
                </div>
                <div className="details">
                  <h6>Collaborative Platform</h6>
                  <p>
                    Simplify loan recovery for financial institutions and
                    repossession agencies with our streamlined platform for
                    communication and task management.
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="w-50">
            <div className="card">
              <div className="card-body d-flex align-items-center flex-column flex-lg-row">
                <div className="logo-container mb-lg-0">
                  <img src={logo8} alt="feature1" />
                </div>
                <div className="details">
                  <h6>Personalized Recovery Plans</h6>
                  <p>
                    Our experts develop personalized recovery plans for clients,
                    providing customized solutions for optimal outcomes based on
                    unique needs.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Container>
  );
};

export default Features;
