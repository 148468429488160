import React, { useEffect, useState } from "react";

import { Modal, ModalHeader, ModalBody } from "reactstrap";
import CustomCloseButton from "./CustomCloseButton";

import Loader from "components/Common/Loader";
import { useHistory } from "react-router-dom";

function PaymentReceipt(props) {
  const logo =
    "https://recovery.newitzone.com/api/public/assets/images/Logo2.png";
  const [modal, setModal] = useState(false);

  const toggle = () => {
    setModal(!modal);
    props.isToggle();
  };

  useEffect(() => {
    setModal(props.isOpen);
  }, [props.isOpen]);

  return (
    <div>
      <Modal
        isOpen={modal}
        toggle={toggle}
        backdrop={false}
        centered
        className="homepage-modal PaymentReceipt-modal"
        size="lg"
      >
        <ModalHeader
          toggle={toggle}
          close={<CustomCloseButton onClick={toggle} />}
          className="header"
        >
          <img src={logo} alt="logo" className="brand-logo" />
        </ModalHeader>
        <ModalBody className="py-5">
          <div className="h1 text-center">Success! </div>
          <div className="status-msg">
            Congratulations on your successful payment! Your subscription plan
            is now active.
          </div>
          <div className="payment-details my-4">
            <ul className="list-group list-group-flush">
              <li className="list-group-item">
                Name: <span>Himanshu Verma</span>
              </li>
              <li className="list-group-item">
                Mobile No: <span>5345456565</span>
              </li>
              <li className="list-group-item">
                Subscription Plan: <span>3 Months</span>
              </li>
              <li className="list-group-item">
                Total Amount Paid:<span>&#x20B9; 14000</span>
              </li>
            </ul>
          </div>
          <div className="row button-container">
            <div className="col-12 col-md-6 mt-2">
              <button
                className="btn btn-block btn-danger"
                onClick={() => {
                  toggle();
                  props.otherModal.logintoggle();
                }}
              >
                Login
              </button>
            </div>
            <div className="col-12 col-md-6 mt-2" onClick={toggle}>
              <button className="btn btn-block btn-outline-danger">
                Close
              </button>
            </div>
          </div>
        </ModalBody>
      </Modal>
    </div>
  );
}

export default Loader(PaymentReceipt);
