import { ErrorMessage, Field, Form, Formik } from "formik";
import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import {
  Button,
  Card,
  CardBody,
  Col,
  FormGroup,
  Label,
  Modal,
  ModalBody,
  Row,
} from "reactstrap";
import { changeStatusAction } from "store/actions";
import * as Yup from "yup";

const ChangeStatusModal = ({
  modalOpen,
  setModalOpen,
  record,
  setLoading,
  formId,
}) => {
  const initialValues = {
    stage: "",
    remark: "",
    file: null,
  };
  const initialStages = ["pending", "close", "hold"];
  const [stage, setStage] = useState(initialStages);

  const validationSchema = Yup.object().shape({
    stage: Yup.string().required("Please Select stage"),
    remark: Yup.string(),
    file: Yup.mixed()
      .test("fileSize", "File size should be less than 1MB", value => {
        if (value) {
          return value.size <= 5 * 1024 * 1024; // 5MB
        }
        return true;
      })
      .test("fileType", "Only PDF, JPEG, and JPG files are allowed", value => {
        if (value) {
          const allowedTypes = [
            "application/pdf",
            "image/jpeg",
            "image/jpg",
            "image/png",
          ];
          return allowedTypes.includes(value.type);
        }
        return true;
      }),
  });

  useEffect(() => {
    if (Object.keys(record).length > 0) {
      setStage(initialStages.filter(val => val != record.stage));
    }
  }, [record]);

  const handleSubmit = async values => {
    try {
      setLoading(true);
      const formData = {};
      formData.stage = values.stage;
      formData.remark = values.remark;
      formData.id = record.id;
      formData.formId = formId;

      if (values.file) {
        const base64File = await convertFileToBase64(values.file);
        formData.base64File = base64File;
      }
      const response = await changeStatusAction(formData);
      if (response.status === 1) {
        toast.success(response.msg);
        setStage(initialStages);
        setLoading(false);
        toggle();
      } else {
        setLoading(false);
        toast.error(response.msg);
      }
    } catch (error) {
      setLoading(false);
      console.log(error);
      let err = error?.response?.data?.msg || "Something went wrong";
      toast.error(err);
    }
  };

  const convertFileToBase64 = file => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();

      reader.onloadend = () => {
        const base64String = reader.result;
        resolve(base64String);
      };

      reader.onerror = error => {
        reject(error);
      };

      reader.readAsDataURL(file);
    });
  };

  const handleFileChange = (event, setFieldValue) => {
    const file = event.currentTarget.files[0];
    setFieldValue("file", file);
  };

  const toggle = () => {
    setModalOpen(!modalOpen);
  };
  return (
    <div>
      <Modal
        isOpen={modalOpen}
        autoFocus={true}
        role="dialog"
        data-toggle="modal"
        toggle={toggle}
        centered={true}
        backdrop="static"
        size="lg"
      >
        <div className="modal-header border-bottom-0">
          <button
            type="button"
            className="btn-close"
            onClick={toggle}
            data-bs-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>
        <ModalBody className="py-3 px-5">
          <Row>
            <Col>
              <div className="h4 text-center">Record Details</div>
            </Col>
          </Row>
          <Row className="fs-6">
            <Col lg={4} md={6}>
              <div className="my-1">
                <span className="fw-bold">Registration Number : </span>
                <span>{record.RegNo}</span>
              </div>
            </Col>
            <Col lg={4} md={6}>
              <div className="my-1">
                <span className="fw-bold">Customer Name : </span>
                <span>{record.CusName}</span>
              </div>
            </Col>
            <Col lg={4} md={6}>
              <div className="my-1">
                <span className="fw-bold">Loan Number : </span>
                <span>{record.LoanNo}</span>
              </div>
            </Col>
            <Col lg={4} md={6}>
              <div className="my-1">
                <span className="fw-bold">Make : </span>
                <span>{record.Make}</span>
              </div>
            </Col>
            <Col lg={4} md={6}>
              <div className="my-1">
                <span className="fw-bold">EMI : </span>
                <span>{record.EMI}</span>
              </div>
            </Col>
            <Col lg={4} md={6}>
              <div className="my-1">
                <span className="fw-bold">POS : </span>
                <span>{record.POS}</span>
              </div>
            </Col>
            <Col lg={4} md={6}>
              <div className="my-1">
                <span className="fw-bold">Chasis Number : </span>
                <span>{record.ChasisNumber}</span>
              </div>
            </Col>
            <Col lg={4} md={6}>
              <div className="my-1">
                <span className="fw-bold">Engine Number : </span>
                <span>{record.EngNo}</span>
              </div>
            </Col>
            <Col lg={4} md={6}>
              <div className="my-1">
                <span className="fw-bold">Bucket : </span>
                <span>{record.Bucket}</span>
              </div>
            </Col>
            <Col lg={4} md={6}>
              <div className="my-1">
                <span className="fw-bold">1st Confirmer Name : </span>
                <span>{record["1stConfirmerName"]}</span>
              </div>
            </Col>
            <Col lg={4} md={6}>
              <div className="my-1">
                <span className="fw-bold">2nd Confirmer Name : </span>
                <span>{record["2ndConfirmerName"]}</span>
              </div>
            </Col>
            <Col lg={4} md={6}>
              <div className="my-1">
                <span className="fw-bold">3rd Confirmer Name : </span>
                <span>{record["3rdConfirmerName"]}</span>
              </div>
            </Col>
            <Col lg={4} md={6}>
              <div className="my-1">
                <span className="fw-bold">1st Confirmer No : </span>
                <span>{record["1stConfirmerNo"]}</span>
              </div>
            </Col>
            <Col lg={4} md={6}>
              <div className="my-1">
                <span className="fw-bold">2nd Confirmer No : </span>
                <span>{record["2ndConfirmerNo"]}</span>
              </div>
            </Col>
            <Col lg={4} md={6}>
              <div className="my-1">
                <span className="fw-bold">3rd Confirmer No : </span>
                <span>{record["3rdConfirmerNo"]}</span>
              </div>
            </Col>
            <Col lg={4} md={6}>
              <div className="my-1">
                <span className="fw-bold">Branch : </span>
                <span>{record["Branch"]}</span>
              </div>
            </Col>
            <Col lg={4} md={6}>
              <div className="my-1">
                <span className="fw-bold">Model : </span>
                <span>{record["Model"]}</span>
              </div>
            </Col>
            <Col lg={4} md={6}>
              <div className="my-1">
                <span className="fw-bold">Sec17 : </span>
                <span>{record["Sec17"]}</span>
              </div>
            </Col>
            <Col lg={4} md={6}>
              <div className="my-1">
                <span className="fw-bold">Seasoning : </span>
                <span>{record["Seasoning"]}</span>
              </div>
            </Col>
            <Col lg={4} md={6}>
              <div className="my-1">
                <span className="fw-bold">TBR : </span>
                <span>{record["TBR"]}</span>
              </div>
            </Col>
            <Col lg={4} md={6}>
              <div className="my-1">
                <span className="fw-bold">Is Live : </span>
                {record.isLive ? (
                  <span className=" badge bg-success">Live</span>
                ) : (
                  <span className="badge bg-danger btn-rounded">Pending</span>
                )}
              </div>
            </Col>
            <Col lg={4} md={6}>
              <div className="my-1">
                <span className="fw-bold">Status : </span>
                {record.status === 1 ? (
                  <span className=" badge bg-success mx-3">Active</span>
                ) : (
                  <span className="badge bg-danger btn-rounded mx-3">
                    Inactive
                  </span>
                )}
              </div>
            </Col>
            <Col lg={4} md={6}>
              <div className="my-1">
                <span className="fw-bold">Stage : </span>
                {record.stage == "pending" ? (
                  <span className="badge" style={{ background: "#2e82f3" }}>
                    {record.stage}
                  </span>
                ) : record.stage == "close" ? (
                  <span className="badge" style={{ background: "#FF0000" }}>
                    {record.stage}
                  </span>
                ) : (
                  <span className="badge" style={{ background: "#FFA500" }}>
                    {record.stage}
                  </span>
                )}
              </div>
            </Col>
          </Row>
          <Row>
            <Col sm="12">
              <Card>
                <CardBody>
                  <Formik
                    initialValues={initialValues}
                    onSubmit={handleSubmit}
                    validationSchema={validationSchema}
                  >
                    {({ setFieldValue }) => (
                      <Form>
                        <FormGroup>
                          <Label htmlFor="stage">Stage:</Label>
                          <Field
                            name="stage"
                            as="select"
                            className="form-select"
                          >
                            <option value="">Select a stage</option>
                            {stage.map((val, i) => (
                              <option value={val} key={i}>
                                {val}
                              </option>
                            ))}
                          </Field>
                          <ErrorMessage
                            name="stage"
                            component="div"
                            className="error"
                          />
                        </FormGroup>

                        <FormGroup>
                          <Label htmlFor="file">File:</Label>
                          <input
                            type="file"
                            name="file"
                            className="form-control"
                            accept=".pdf, image/*"
                            onChange={event =>
                              handleFileChange(event, setFieldValue)
                            }
                          />
                          <ErrorMessage
                            name="file"
                            component="div"
                            className="error"
                          />
                        </FormGroup>

                        <FormGroup>
                          <Label htmlFor="remark">Remark:</Label>
                          <Field
                            as="textarea"
                            name="remark"
                            className="form-control"
                            rows="4"
                          />
                          <ErrorMessage
                            name="remark"
                            component="div"
                            className="error"
                          />
                        </FormGroup>

                        <Button type="submit">Save</Button>
                      </Form>
                    )}
                  </Formik>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </ModalBody>
      </Modal>
    </div>
  );
};

export default ChangeStatusModal;
