import React, { useEffect, useState } from "react";
import logo from "../../assets/images/homepage/logo1.png";
import LoginModal from "./LoginModal";
import { Link } from "react-router-dom";
import {
  Collapse,
  Nav,
  NavItem,
  NavLink,
  Navbar,
  NavbarBrand,
  NavbarToggler,
} from "reactstrap";
import ForgetModal from "./ForgetModal";
import SignUpModal from "./SignUpModal";
import MemberShipModal from "./MemberShipModal";
import OTPVerificationModal from "./OTPVerificationModal";
import PaymentReceipt from "./PaymentReceipt";
import { scrollToSection } from "helpers/common_helper";

const NavBar = () => {
  const [loginModal, setLoginModal] = useState(false);
  const [forgetModal, setForgetModal] = useState(false);
  const [signUpModal, setSignUpModal] = useState(false);
  const [OTPVerifyModal, setOTPVerifyModal] = useState(false);
  const [membershipModal, setMembershipModal] = useState(false);
  const [paymentModal, setPaymentModal] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [isSticky, setIsSticky] = useState(false);

  const forgetToggle = () => {
    setForgetModal(!forgetModal);
  };
  const signUpToggle = () => {
    setSignUpModal(!signUpModal);
  };
  const logintoggle = () => {
    setLoginModal(!loginModal);
  };
  const membershipToggle = () => {
    setMembershipModal(!membershipModal);
  };
  const OTPVerifyToggle = () => {
    setOTPVerifyModal(!OTPVerifyModal);
  };
  const paymentToggle = () => {
    setPaymentModal(!paymentModal);
  };
  const toggle = () => setIsOpen(!isOpen);

  const handleScroll = () => {
    if (window.scrollY > 50) {
      setIsSticky(true);
    } else {
      setIsSticky(false);
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);
  return (
    <Navbar
      expand="lg"
      dark
      container={false}
      className={`${isSticky ? "fixed-nav" : ""}`}
    >
      <NavbarBrand href="/">
        <img
          src={"https://recovery.newitzone.com/api/public/profile/logo.png"}
          alt="logo"
          className="ms-xl-0 brand-logo"
        />
      </NavbarBrand>
      <NavbarToggler onClick={toggle} className="ms-auto" />
      <Collapse isOpen={isOpen} navbar>
        <Nav className="ms-auto quadrilateral-shape" navbar>
          <NavItem>
            <a className="nav-link" onClick={e => scrollToSection(e, "home")}>
              Home
            </a>
          </NavItem>
          <NavItem>
            <a
              className="nav-link"
              onClick={e => scrollToSection(e, "aboutus")}
            >
              About
            </a>
          </NavItem>
          <NavItem>
            <a
              className="nav-link"
              onClick={e => scrollToSection(e, "features")}
            >
              Features
            </a>
          </NavItem>
          <NavItem>
            <a
              className="nav-link"
              onClick={e => scrollToSection(e, "testimonial")}
            >
              Testmonials
            </a>
          </NavItem>
          <NavItem>
            <a
              className="nav-link"
              onClick={e => scrollToSection(e, "contact")}
            >
              Contact
            </a>
          </NavItem>
          <NavItem>
            {localStorage.getItem("authUser") ? (
              <NavLink tag={Link} to="/panel/dashboard" className="pe-0">
                <div className="nav-btn">Account</div>
              </NavLink>
            ) : (
              <div className="nav-btn " onClick={logintoggle}>
                Login
              </div>
            )}
          </NavItem>
        </Nav>
      </Collapse>
      <LoginModal
        isOpen={loginModal}
        isToggle={logintoggle}
        otherModal={{ forgetToggle, signUpToggle }}
      />
      <SignUpModal
        isOpen={signUpModal}
        isToggle={signUpToggle}
        otherModal={{ logintoggle, OTPVerifyToggle }}
      />
      <OTPVerificationModal
        isOpen={OTPVerifyModal}
        isToggle={OTPVerifyToggle}
        otherModal={{ membershipToggle }}
      />
      <MemberShipModal
        isOpen={membershipModal}
        isToggle={membershipToggle}
        otherModal={{ paymentToggle }}
      />
      <PaymentReceipt
        isOpen={paymentModal}
        isToggle={paymentToggle}
        otherModal={{ logintoggle }}
      />
      <ForgetModal isOpen={forgetModal} isToggle={forgetToggle} />
    </Navbar>
  );
};

export default NavBar;
