import React from "react";
import aboutImg from "../../assets/images/homepage/aboutUs.jpg";
import { Row } from "reactstrap";

const AboutUs = () => {
  return (
    <div id="aboutus" className="aboutus mb-5">
      <div className="container-fluid p-lg-0 position-relative">
        <div className="card text-center d-lg-none">
          <img src={aboutImg} alt="aboutUs" className="card-img-top" />{" "}
          <div className="card-body">
            <h3 className="underline mb-4">About Us</h3>
            <p>
              Recoveryfy is a groundbreaking solution that provides a common
              platform for banks, NBFCs (Non-Banking Financial Companies), and
              their authorized repossession agencies. Our mission is to simplify
              and optimize the loan recovery process by offering a centralized
              hub where financial institutions and repossession agencies can
              collaborate seamlessly.
            </p>
            <br />
            <p>
              With our innovative platform, we facilitate efficient
              communication, data sharing, and task management between all
              stakeholders involved in the loan recovery journey. By leveraging
              technology and industry expertise, we empower banks, NBFCs, and
              repossession agencies to work together effectively, improving
              recovery rates and reducing operational inefficiencies.
            </p>
          </div>
        </div>

        <Row className="p-0 m-0 d-none d-lg-flex">
          <div className="m-0 p-0 image-container">
            <img src={aboutImg} alt="aboutUs" className="img-fluid" />
          </div>

          <div className="card">
            <div className="card-body p-xl-0">
              <h3 className="underline">About Us</h3>
              <div className="d-flex flex-column g-32">
                <p>
                  Recoveryfy is a groundbreaking solution that provides a common
                  platform for banks, NBFCs (Non-Banking Financial Companies),
                  and their authorized repossession agencies. Our mission is to
                  simplify and optimize the loan recovery process by offering a
                  centralized hub where financial institutions and repossession
                  agencies can collaborate seamlessly.
                </p>
                <p>
                  With our innovative platform, we facilitate efficient
                  communication, data sharing, and task management between all
                  stakeholders involved in the loan recovery journey. By
                  leveraging technology and industry expertise, we empower
                  banks, NBFCs, and repossession agencies to work together
                  effectively, improving recovery rates and reducing operational
                  inefficiencies.
                </p>
              </div>
            </div>
          </div>
        </Row>
      </div>
    </div>
  );
};

export default AboutUs;
